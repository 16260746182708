import { DefaultApiLotsLotIdGetRequest, ItemsGet200ResponseRowsInnerLotsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import DefaultInstance from "instance/axios";
import { useQuery } from "react-query";

export const GET_LOT_QUERY_KEY = "/lot";

const getLotFetch = (params: DefaultApiLotsLotIdGetRequest) => {
    const { lotId } = params;
    return DefaultInstance.lotsLotIdGet({
        lotId: lotId
    });
};

const useGetLotsLotIdQuery = (
    params?: DefaultApiLotsLotIdGetRequest,
    options?: {
        onSuccess: (data: ItemsGet200ResponseRowsInnerLotsInner) => void;
    }
) => {
    return useQuery<AxiosResponse<ItemsGet200ResponseRowsInnerLotsInner, any[]>>({
        queryKey: [GET_LOT_QUERY_KEY, params?.lotId],
        queryFn: () => getLotFetch({ lotId: params?.lotId as number }),
        enabled: !!params?.lotId,
    });
};

export default useGetLotsLotIdQuery;
