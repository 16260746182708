/**
 * 작성자명 : 원종석
 * 작성일자 : 2023.12.01 (금)
 * 작성내용 : 천 단위마다 숫자형 데이터에 ','를 표시한다.
 * 수정자명 : 원종석
 * 수정일자 : 2024.03.05 (화)
 * 수정내용 : 입력값이 undefined일 경우에도 그대로 반환한다.
 * 수정일자 : 2024.03.07 (목)
 * 수정내용 : 입력값이 falsy할 경우 빈 문자열을 반환한다.
 * @param data 숫자 또는 문자열 형태의 데이터
 * @returns 천 단위마다 ','를 표시한 문자열 데이터
 */
export const setToLocaleString = (
    data: number | string | undefined | null
  ): string => {
    // 입력값이 null 또는 undefined일 경우, 빈 값을 반환한다.
    if (data === null || data === undefined) return "";
    // 입력 데이터가 문자열일 경우, 숫자 형식으로 변환한다.
    const target = typeof data === "string" ? Number(data) : data;
    return target.toLocaleString("ko-KR");
  };
  
  /**
   * 작성자명 : 원종석
   * 작성일자 : 2023.12.01 (금)
   * 작성내용 : 입력값에 맞는 소수점을 표시한 데이터를 반환한다.
   * 수정일자 : 2023.12.06 (수)
   * 수정내용 : 계산할 데이터가 number 형식의 데이터가 아닐 경우(isNaN) 0을 반환한다.
   * 1. 입력값 data에 소수점이 없다면 그대로 반환한다. 예를 들어 50이 입력되면 digit 값에 관계 없이 그대로 50을 반환한다.
   * 2. 입력값 data에 소수점이 있다면 digit에 맞게 소수점을 표시한다. digit보다 입력값 data의 소수점이 적은 경우 입력값을 그대로 반환한다.
   * 3. 입력값 data에 소수점이 digit보다 길 경우 digit에 맞춰서 반환한다. 입력값에 반올림이 필요한 경우, 반올림한 값을 반환한다.
   * @param data 숫자 또는 문자열 형태의 데이터
   * @param digit (선택) 소수점 자리. 입력하지 않을 경우 0
   * @returns 입력값에 맞게 소수점을 표시한 데이터.
   */
  export const setFilteringDecimalPoint = (
    data: number | string,
    digit: number = 0
  ): number => {
    // 입력 데이터가 문자열일 경우, 숫자 형식으로 변환한다.
    const target = typeof data === "string" ? Number(data) : data;
    if (isNaN(target)) return 0;
    return parseFloat(target.toFixed(digit));
  
    // 소수점을 digit 값에 맞도록 강제로 표시하고 싶다면 return type을 string으로 변경한다.
    // 또한 해당 공통 함수가 적용될 코드에도 return type을 string으로 변경하여 사용한다.
    // return target.toFixed(digit);
  };
  