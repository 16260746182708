import { MediaQuery } from "@mantine/core";
import { deviceSize } from "styles/theme";
import './styles.css';
import { WorkLeftSectionProps } from "./types";

export const WorkLeftSection = (params: WorkLeftSectionProps) => {
  const { children } = params;

  return (
    <MediaQuery
      query={`(max-width: ${deviceSize.tablet})`}
      className="overflowVisibleImportant"
      styles={{
        width: "100% !important",
        borderRight: "none !important",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          overflowY: "auto",
        }}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          padding: "1.5rem 1.5rem",
          gap: "8px",
          width: "100%",
          borderRight: "1px solid white",
        }}>
          {children}
        </div>
      </div>
    </MediaQuery>
  );
};
