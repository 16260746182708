interface WorkThProps {
  children: React.ReactNode;
  height?: number;
  width?: number;
  align?: "left" | "center" | "right";
  flex? : number;
}

export const TableHeader = (params: WorkThProps) => {
  const { children, height, width, align, flex } = params;
  return (
    <th
      style={{
        fontSize: "1.5rem",
        padding : "0.5rem 0.625rem",
        flex: `${flex}`,
      }}
      {...params}
    >
      {children}
    </th>
  );
};
