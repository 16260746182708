import styled from "@emotion/styled";
import { Text } from "@mantine/core";
import { InspectionsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

interface InspectionsRowProps {
  data: InspectionsGet200ResponseRowsInner;
}

export const InspectionsRow = (params: InspectionsRowProps) => {
  const { itemCode, isPassed, inspectionAt, specificationData, isDone, lot } = params.data;

  const summaryNames = specificationData?.criteria?.map((data) => data.name);

  return (
    <InspectionsRowWrapper color="transparent">
      <Td width={20}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {lot?.name ?? "-"}
        </Text>
      </Td>
      <Td width={20}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {itemCode}
        </Text>
      </Td>
      <Td width={25}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {inspectionAt
            ? dayjs(inspectionAt).format("YYYY-MM-DD hh:mm:ss")
            : "-"}
        </Text>
      </Td>
      <Td width={25}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {(summaryNames?.length as number) > 1
            ? summaryNames?.[0] +
            " 외 " +
            ((summaryNames?.length as number) - 1) +
            "개"
            : summaryNames?.[0]}
        </Text>
      </Td>
      <Td width={10}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {isDone ? '검사중' : isPassed ? "합격" : "불합격"}
        </Text>
      </Td>
    </InspectionsRowWrapper>
  );
};

const InspectionsRowWrapper = styled.tr<{ color: string }>`
  box-sizing: border-box;
  background-color: ${(props) => props.color};
  gap: 0px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  border-bottom: 1px solid white;
  width: 100%;

  &:hover {
    background-color: #999;
    opacity: 0.8;
  }
`;

const Td = styled.td<{ width?: number; seq?: number }>`
  padding: 2rem 2rem;
  align-self: stretch;
`;
