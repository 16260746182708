import { Button } from "@mantine/core";
import { PlusFormButtonProps } from "./types";

export const BoxPlusFormButton = (props: PlusFormButtonProps) => {
  const { num, color, onChange, ...etcParams } = props;

  const onClick = () => {
    onChange && onChange(Number(etcParams?.value) + num);
  };

  return (
    <Button onClick={onClick} {...etcParams}>
      +{num}
    </Button>
  );
};

export default BoxPlusFormButton;


