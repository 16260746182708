import {
    ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { WORKS_WORK_ID_GET_KEY } from "api/work/useWorksWorkIdGetQuery";
import { ProductionInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

interface CancelParams extends ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest { }

type MutationType = "cancel"

type Params<T extends MutationType> = T extends "cancel" ? CancelParams : never;

const cancelInputFetcher = (params: CancelParams) =>
    ProductionInstance.workLogsWorkLogIdCancelInputPut(params);

const useCancelInputPutMutation = <T extends MutationType>(type: T) => {

    const queryClient = useQueryClient();

    const cancelMutation = useMutation(
        (params: Params<typeof type>) => cancelInputFetcher(params as CancelParams),
        {
            onSuccess: () => {
                customNotification.success({
                    message: "실적이 성공적으로 취소되었습니다.",
                });
                queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
            },
            onError: (error: any) => {
                customNotification.error({
                    message: error.response.data.message
                });
            }
        }
    );

    if (type === "cancel") {
        return cancelMutation;
    } else throw new Error("Invalid mutation type");
};

export default useCancelInputPutMutation;
