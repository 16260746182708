import { ActionIcon, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconDeviceFloppy, IconSettings } from "@tabler/icons-react";
import { useModal } from "context/ModalStackManager";
import { EndSetForm } from "features/form/work/EndSetForm";
import { BoxNumberInput } from "features/work/boxNumberInput";
import BoxPlusFormButton from "features/work/boxPlusformButton";
import { WorkButton as EndButton } from "features/work/workButton";
import { WorkTitle } from "features/work/workTitle";
import { useEffect } from "react";
import { ReportBoxMain } from "./reportBoxMain";
import { EndReportBoxProps } from "./types";

const ReportBox = Object.assign(ReportBoxMain, {
  EndTitle: WorkTitle,
  SettingName: WorkTitle,
  Input: BoxNumberInput,
  PlusFormButton: BoxPlusFormButton,
  EndButton: EndButton,
});

export const WorkEndReportBox = (params: EndReportBoxProps) => {
  const { openModal } = useModal();
  const {
    value,
    disabled,
    onSubmit,
    externalCounterSetting,
    onChangeSetting,
    activeType,
  } = params;

  const form = useForm({
    initialValues: {
      end: value
    },
  });

  useEffect(() => {
    form.setFieldValue("end", value);
  }, [value]);

  useEffect(() => {
    if (
      externalCounterSetting === "COUNTER_AND_AUTO_REPORT" &&
      (activeType === "WORKING" || activeType === "PAUSED")
    ) {
      onSubmit && onSubmit(form.values.end + "");
    }

  }, [externalCounterSetting, value, activeType]);

  return (
    <ReportBox>
      <Flex
        direction={"row"}
        justify={"space-between"}
        align={"center"}
        my={'3rem'}
      >
        <ReportBox.EndTitle
          size={"3rem"}
          c={"#FFFFFF"}
          weight={700}
          lh={"130%"}
        >
          {"생산실적 입력"}
        </ReportBox.EndTitle>
        <ActionIcon size={"xl"}>
          <IconSettings
            onClick={() =>
              openModal(
                <EndSetForm
                  activeType={activeType}
                  defaultValue={externalCounterSetting}
                  onSubmit={onChangeSetting}
                />,
                "",
                ""
              )
            }
          />
        </ActionIcon>
      </Flex>
      <Flex direction={"row"} justify={"flex-end"} align={"center"} gap={'0.5rem'}>
        <ReportBox.Input
          size="md"
          styles={{
            input: {
              width: "30rem",
              height: "4.5rem",
              borderRadius: "0.5rem",
            },
          }}
          disabled={disabled}
          {...form.getInputProps("end")}
          value={Number(form.values.end)}
        />
        <ReportBox.PlusFormButton
          bg={'cyan'}
          w="6rem"
          h="4.5rem"
          disabled={disabled}
          num={1}
          {...form.getInputProps("end")}
        />
        <ReportBox.PlusFormButton
          bg={'indigo'}
          w="6rem"
          h="4.5rem"
          disabled={disabled}
          num={5}
          {...form.getInputProps("end")}
        />
        <ReportBox.EndButton
          onClick={() => {
            onSubmit && onSubmit(form.values.end)
            form.setValues({ end: "0" });
          }}
          disabled={disabled}
          type={"submit"}
          rightIcon={<IconDeviceFloppy />}
        >
          {"실적 저장"}
        </ReportBox.EndButton>
      </Flex>
    </ReportBox>
  );
};
