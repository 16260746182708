import styled from "@emotion/styled";

interface FormButtonBoxProps {
    children: React.ReactNode;
}

export const FormButtonBox = ({ children }: FormButtonBoxProps) => {
    return <ButtonBox>{children}</ButtonBox>
};

const ButtonBox = styled.div`
    padding : 24px 0 ;
    display : flex;
    justify-content : flex-end;
    gap : 20px;
    margin-right : 12px;
`
