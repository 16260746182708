import styled from "@emotion/styled";
import { ActionIcon, Chip, Flex, Group, Pagination, Table, Text, Title } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import useWorksGetQuery from "api/works/useWorksGetQuery";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import dayjs from "dayjs";
import { WorkRow } from "features/works/WorkRow";
import DateRangePicker from "features/works/components/DateRangePicker";

import { useEffect, useState } from "react";
import { theme } from "styles/theme";
import { getBeforeWorkingDayAndToday } from "utils/DateUtil";

const { WAITING, PAUSED, WORKING, DONE } = ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;

export const Works = () => {
  const [activePage, setPage] = useState<number>(1);
  const { openModal, closeModal } = useModal();
  const [dates, setDates] = useState<[Date, Date]>(getBeforeWorkingDayAndToday());
  const [value, setValue] = useState<string[]>([]);
  const { equipmentCode } = useLocalStorage();

  const { data, refetch } = useWorksGetQuery({
    activePage,
    query: {
      $and: [(equipmentCode !== null && equipmentCode !== 'null') ? { equipmentCode: { $eq: equipmentCode } } : {}, {
        trackingStatus: value.length > 0 ? value : [WAITING, PAUSED, WORKING, DONE]
      },
      ]
    },
    sort: "scheduleSeq",
    dates
  });

  useEffect(() => {
    setPage(1);
    refetch();
  }, [dates, refetch, value]);

  return (
    <Container>
      <HeaderContent>
        <CalendarContent>
          <TimeContent>
            {dayjs(dates[0] ?? undefined).format("YYYY-MM-DD")} ~ {dayjs(dates[1] ?? undefined).format("YYYY-MM-DD")}
          </TimeContent>
          <ActionIcon size="4rem" onClick={() => {
            openModal(<DateRangePicker dates={dates} />, null, "날짜 선택", true).then((result: [Date, Date]) => { if (Array.isArray(result)) setDates(result) });
          }}><IconCalendar size="4rem" /></ActionIcon>
        </CalendarContent>
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="row"
        >
          <Chip.Group multiple value={value} onChange={setValue}>
            <Group position="center">
              <StatusChip value={WAITING} variant="filled" size="lg" radius="xs">대기</StatusChip>
              <StatusChip value={WORKING} variant="filled" size="lg" radius="xs">진행중</StatusChip>
              <StatusChip value={PAUSED} variant="filled" size="lg" radius="xs">일시정지</StatusChip>
              <StatusChip value={DONE} variant="filled" size="lg" radius="xs">완료</StatusChip>
            </Group>
          </Chip.Group>
        </Flex>
      </HeaderContent>
      <Table verticalSpacing={"xl"} horizontalSpacing={"xl"}>
        <Thead>
          <Tr>
            <Th seq={1}>
              <Text fz="md" fw={500} span>
                작업 순서
              </Text>
            </Th>
            <Th seq={2} flex={2}>
              <Text fz="md" fw={500} span>
                라인명(설비명)
              </Text>
            </Th>
            <Th seq={3} flex={2}>
              <Div>
                <Text fz="md" fw={500} span>
                  제품(공정코드)
                </Text>
              </Div>
            </Th>
            <Th seq={4}>
              <Text c={theme.colors?.blue?.[9]} fz="md" fw={500} span>
                계획 수량
              </Text>
            </Th>
            <Th seq={5} flex={2}>
              <Text fz="md" fw={500} span>
                규격
              </Text>
            </Th>
            <Th seq={6} flex={2}>
              <Text fz="md" fw={500} span>
                작업일
              </Text>
            </Th>
            <Th seq={7}>
              <Text fz="md" fw={500} span>
                상태
              </Text>
            </Th>
            <Th seq={8}>
              <Text fz="md" fw={500} span>
                비고
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.data.rows?.map((work, index) => {
            return <WorkRow key={work.id} data={work} />;
          })}
        </Tbody>
      </Table>
      <Flex justify={"center"} w={"100%"} mt={50}>
        <Pagination
          align="center"
          onChange={setPage}
          value={activePage}
          total={data?.data?.totalPages ?? 0}
          size="xl"
          radius="sm"
        />
      </Flex>
    </Container>
  );
};

const Thead = styled.thead`
  width: 100%;
`;
const Tbody = styled.tbody`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

const Th = styled.th<{ seq?: number; flex? : number }>`
  display: flex;
  flex-direction: column;
  flex : ${(props) => (props.flex ? props.flex : 1)};
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: pre-line;
  word-break: break-all;
  /* border: 0.0625rem solid ${theme.colors?.gray?.[3]}; */
`;

const Div = styled.div`
  display: flex;
  width: 50%;
`;

const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 8px;
    align-self: stretch;
`

const TimeContent = styled(Title)`
  font-family: Noto Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: white;
`

const CalendarContent = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
`;

const StatusChip = styled(Chip)`
  label {
    height: 4rem;
  }
`
const Container = styled.div`
  overflow: auto;
  height: 100%;
`;