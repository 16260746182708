import styled from "@emotion/styled";
import { theme } from "styles/theme";

interface HeaderProps {
  children: React.ReactNode;
}

export const WorkHeader = ({ children }: HeaderProps) => {
  return (
    <Content>
      <ButtonBox>{children}</ButtonBox>
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
  position: sticky;
  background-color: ${theme.colors?.dark?.[4]};
`;

const ButtonBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
`;
