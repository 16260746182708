import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { LocalStorageProvider } from "context/LocalStorageProvider";
import ModalStackManager from "context/ModalStackManager";
import QueryProvider from "provider/QueryProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { IoProvider } from "socket.io-react-hook";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "./styles/Global";
import { theme } from "./styles/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <LocalStorageProvider>
        <IoProvider>
          <RecoilRoot>
            <QueryProvider>
              <ModalStackManager>
                <MantineProvider theme={theme}>
                  <Notifications />
                  <GlobalStyle />
                  <App />
                </MantineProvider>
              </ModalStackManager>
            </QueryProvider>
          </RecoilRoot>
        </IoProvider>
      </LocalStorageProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
