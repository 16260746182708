import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import DefaultInstance from "instance/axios";
import { useQuery } from "react-query";
import { WORK_LOG_GET_KEY } from "./useWorkLogsGetQuery";

const fetchWorkLogsFindPost = (params?: any) => {
  const query: any = {};

  if (params.query.$and.length > 0) {
    query["query"] = JSON.stringify(params.query);
  }

  if (params.search) {
    query["search"] = params.search;
    query["searchFields"] = params.searchFields;
  }

  return DefaultInstance.workLogsFindPost({
    ...query,
    page: params.activePage ?? 1,
    pageSize: 10,
    sort: params.sort,
    populate: ["downtimeReasonName", "defectName", "itemUnit", "alreadyCanceled"],
  });
};

export const useWorkLogsFindPost = (
  params: { query: any, sort: string },
  options?: {
    onSuccess: (work: WorkLogsGet200ResponseRowsInner) => void;
  }
) => {
  return useQuery([WORK_LOG_GET_KEY, JSON.stringify(params.query), params.sort], () =>
    fetchWorkLogsFindPost(params)
  );
};
