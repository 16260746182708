import styled from "@emotion/styled";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import { UserUpdate } from "features/form/user/UserUpdateForm";
import { deviceSize, theme } from "styles/theme";
export const Footer = () => {
  const { operationCode, equipmentCode } = useLocalStorage();
  const { openModal } = useModal();

  return (
    <>
      <Container>
        <NameBox>
          <p>{localStorage.getItem("userName") ?? "-"}</p>
        </NameBox>
        <RoutingBox>
          <p>{operationCode !== 'undefined' ? operationCode : "-"}</p>
        </RoutingBox>
        <EquimentBox>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => openModal(<UserUpdate />, "", "")}
          >
            {equipmentCode === null || equipmentCode === "null"
              ? "*지정된 설비 없음"
              : equipmentCode}
          </p>
        </EquimentBox>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 80px;
  background-color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
  padding : 1rem;

  p {
    color: ${theme.colors?.gray?.[2]};
    font-size: 34px;
    font-weight: 700;
  }

  @media (max-width: ${deviceSize.tablet}) {
    height: 74px;
    p {
      font-size: 30px;
      font-weight: 600;
    }
  }

  @media (max-width: ${deviceSize.mobileM}) {
    height: 65px;
    p {
      font-size: 20px;
      font-weight: 400;
    }
  }
`;

const NameBox = styled.div`
  text-align: left;
  flex : 1;
`;
const RoutingBox = styled.div`
  text-align: center;
  flex : 1;
`;

const EquimentBox = styled.div`
  text-align: right;
  flex : 1;
`;
