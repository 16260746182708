import { Table, TableProps } from "@mantine/core";

export const TableMain = (params: TableProps) => {
  const { children } = params;
  return (
    <Table striped highlightOnHover withBorder withColumnBorders {...params}>
      {children}
    </Table>
  );
};
