import { Button, ButtonProps } from "@mantine/core";

export const WorkButton = (
  params: ButtonProps & React.HTMLAttributes<HTMLButtonElement>
) => {
  const { children, bg } = params;

  return (
    <Button
      styles={{
        root: {
          "&:hover": {
            backgroundColor: bg as string,
            opacity: 0.9,
          },
        },
        label: {
          marginTop: "0.4rem",
        },
      }}
      h={"4.5rem"}
      miw={"8rem"}
      size="md"
      {...params}
    >
      {children}
    </Button>
  );
};
