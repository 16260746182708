import { Button, Group, Radio, Select, Table, TextInput } from "@mantine/core";

import { useForm } from "@mantine/form";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import useInventoryMutation from "api/inventories/useInventoriesMutationQuery";
import { useModal } from "context/ModalStackManager";
import { InventoriesAutoComplete } from "features/autoComplete/inventory/inventories-autoComplete";
import { ItemAutoComplete } from "features/autoComplete/item/item-autoComplete";
import { LotAutoComplete } from "features/autoComplete/lots/lots-autoComplete";
import { InventoryBarcodeInput } from "features/barcode/inventory/inventoriesBarcodeInput";
import { FormButtonBox } from "features/form/FormButtonBox";
import { FormMain } from "features/form/FormMain";
import { FormWrapper } from "features/form/FormWrapper";
import { useState } from "react";
import { customNotification } from "utils/notificationShow";

const Create = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Select: Select,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  LotAutoComplete: LotAutoComplete,
  InventoriesAutoComplete: InventoriesAutoComplete,
  ItemAutoComplete: ItemAutoComplete,
});

export interface WorkInputFormProps {
  workData: any;
  lotId?: number;
  onInputSuccess?: () => void;
}

export interface OperationBomProps {
  itemCode: string;
  operationCode: string;
  item: {
    name: string;
  };
}

export const RawMaterialInputForm = ({
  workData,
  onInputSuccess
}: WorkInputFormProps) => {
  const [itemCodes, setItemCodes] = useState<string | undefined>();
  const { closeModal } = useModal();
  const { mutate: postMutate } = useInventoryMutation("workInput");

  const form = useForm({
    initialValues: {
      barcodeInput: '',
      workId: workData?.id,
      worksWorkIdInputPutRequest: {
        lotId: undefined,
        sourceLocationCode: undefined,
        // operationsData는 배열이므로, find로 해당 operationCode에 맞는 데이터를 찾아야 함
        targetLocationCode:
          workData?.equipment?.toLocationCode ??
          workData?.operationData?.routing?.toLocationCode ??
          workData?.productionPlan?.operationsData.find(
            (data: any) => data.code === workData.operationCode
          ).routing.toLocationCode,
        quantity: '0',
      },
    },
  });


  useInventoriesGetQuery(
    form.values.worksWorkIdInputPutRequest.lotId ? {
      query: {
        $and: [
          {
            itemCode: itemCodes ?? workData?.operationData?.operationBoms?.map((value: OperationBomProps) => value.itemCode)

          },
          {
            lotId: { $eq: form.values.worksWorkIdInputPutRequest.lotId }
          },
          {
            locationCode:
              workData?.operationData?.routing?.fromLocationCode ??
              workData?.productionPlan?.operationsData.find(
                (data: any) => data.code === workData.operationCode
              ).routing.fromLocationCode,
          },
          {
            quantity: { $gt: 0 },
          }
        ],
      }
    } : undefined
    , {
      onSuccess: (data) => {
        if (data.rows) {
          if (data.rows.length === 0) {
            form.setFieldValue('worksWorkIdInputPutRequest.quantity', 0)
            form.setFieldValue('barcodeInput', '')
            form.setFieldValue('lotId', '')
            return customNotification.error({ message: '바코드에 해당하는 로트가 존재하지 않습니다.' })
          }
          form.setFieldValue('worksWorkIdInputPutRequest.quantity', data?.rows[0]?.quantity)
          form.setFieldValue('barcodeInput', '')
          form.setFieldValue('lotId', '')
        }
      }
    })


  const onSubmit = () => {
    try {
      postMutate(
        {
          workId: form.values.workId && (form.values.workId as number),
          worksWorkIdInputPutRequest: {
            lotId:
              form.values.worksWorkIdInputPutRequest.lotId &&
              parseInt(form.values.worksWorkIdInputPutRequest.lotId),
            sourceLocationCode:
              workData?.operationData?.routing?.fromLocationCode,
            targetLocationCode:
              form.values.worksWorkIdInputPutRequest.targetLocationCode &&
              (form.values.worksWorkIdInputPutRequest
                .targetLocationCode as string),
            quantity:
              form.values.worksWorkIdInputPutRequest.quantity &&
              (form.values.worksWorkIdInputPutRequest.quantity as string),
          },
        },
        {
          onSuccess: () => {
            customNotification.success({
              message: "원/부자재가 성공적으로 투입되었습니다.",
            });
            onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
            closeModal(form.values);
          },
        }
      );
    } catch (e) {
      customNotification.error({
        message: "원/부자재 투입에 실패하였습니다.",
      });
    }
  };

  const invalidConfirm = form.values.worksWorkIdInputPutRequest.lotId === undefined
    || form.values.worksWorkIdInputPutRequest.quantity === '0'
    || form.values.worksWorkIdInputPutRequest.quantity === '';

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      form.setFieldValue("worksWorkIdInputPutRequest.lotId", form.values.barcodeInput);
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    form.setFieldValue("worksWorkIdInputPutRequest.lotId", inputValue);
  };

  return (
    <>
      <Create.Wrapper>
        <Table>
          <thead>
            <tr>
              <th>
                <span
                  style={{ marginInline: "3rem 12rem", fontSize: "1.4rem" }}
                >
                  품목코드
                </span>
                <span
                  style={{ fontSize: "1.4rem", marginInline: "2rem 16rem" }}
                >
                  품목 명
                </span>
                <span style={{ fontSize: "1.4rem" }}>공정코드</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <Radio.Group
              value={itemCodes}
              onChange={(e) => {
                setItemCodes(e);
                form.setFieldValue(
                  "worksWorkIdInputPutRequest.lotId",
                  undefined
                );
              }}
            >
              {workData?.operationData?.operationBoms?.map(
                (value: OperationBomProps, index: number) => {
                  return (
                    <tr key={index}>
                      <Group style={{ gap: "2rem" }}>
                        <Radio value={value.itemCode} />
                        <td width="180rem">{value.itemCode}</td>
                        <td width="180rem">{value.item.name}</td>
                        <td width="180rem">{value.operationCode}</td>
                      </Group>
                    </tr>
                  );
                }
              )}
            </Radio.Group>
          </tbody>
        </Table>
        <InventoryBarcodeInput
          // value={lotId as string}
          handleBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...form.getInputProps("barcodeInput")}
        />
        <Create.InventoriesAutoComplete
          key={form.values.worksWorkIdInputPutRequest.lotId}
          label="로트 정보"
          withAsterisk
          workData={workData}
          itemCodes={itemCodes ?? workData?.operationData?.operationBoms?.map((value: OperationBomProps) => value.itemCode)}
          {...form.getInputProps("worksWorkIdInputPutRequest.lotId")}
          size="lg"
          styles={{ label: { fontSize: "0.9em" } }}

        />
        <Create.Input
          label="출고 로케이션 코드"
          withAsterisk
          disabled
          size="lg"
          value={workData?.operationData?.routing?.fromLocationCode}
          styles={{ label: { fontSize: "0.9em" } }}
        />
        <Create.Input
          label="입고 로케이션 코드"
          disabled
          size="lg"
          styles={{ label: { fontSize: "0.9em" } }}
          {...form.getInputProps(
            "worksWorkIdInputPutRequest.targetLocationCode"
          )}
        />
        <Create.Input
          withAsterisk
          type="number"
          label="수량"
          size="lg"
          styles={{ label: { fontSize: "0.9em" } }}
          {...form.getInputProps("worksWorkIdInputPutRequest.quantity")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal} size="lg" fz="xl">
            취소
          </Create.Button>
          <Create.Button
            disabled={invalidConfirm}
            onClick={onSubmit} size="lg" fz="xl">
            저장
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
