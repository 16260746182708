import useEquipmentsEquipmentIdGet from "api/equipmentCount/useEquipmentsEquipmentIdGet";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useEffect } from "react";
import { useSocket, useSocketEvent } from "socket.io-react-hook";
import { UseGetEquipmentCounterProps } from "./types";

export const useGetEquipmentCounter = ({
  equipmentCode,
  equipmentId,
  enabled = true,
}: UseGetEquipmentCounterProps): {
  equipmentCounter: string;
  equipmentLimitCounter: string;
  isLimitHit: boolean;
} => {

  const { setTempWork, tempWork } = useLocalStorage()

  const { socket } = useSocket(process.env.REACT_APP_BASE_URL as string, {
    autoConnect: true,
    enabled: enabled,
  });

  const { refetch } = useEquipmentsEquipmentIdGet({
    equipmentId: equipmentId,
  }, {
    enabled: false,
    onSuccess: (res) => {
      setTempWork({
        ...tempWork,
        end: res?.data?.equipmentCounter
      })
    }
  })

  const roomName = equipmentCode ? `EC_${equipmentCode}` : "";

  if (roomName) {
    socket.emit("call", "socket.join", { room: roomName })
  }

  const { lastMessage } = useSocketEvent(socket, roomName);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('페이지가 비활성화되었습니다.');
      } else {
        console.log('페이지가 활성화되었습니다.');
        refetch()
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // cleanup function to remove event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [socket, refetch]);

  useEffect(() => {
    if (lastMessage) {
      setTempWork({
        ...tempWork,
        end: lastMessage?.equipmentCounter
      })
    }
  }, [lastMessage])


  return {
    equipmentCounter: tempWork?.end ?? "0",
    equipmentLimitCounter: lastMessage?.equipmentLimitCounter,
    isLimitHit: lastMessage?.isLimitHit,
  };
};