import styled from "@emotion/styled";
import { deviceSize } from "styles/theme";
import { WorkBodyProps } from "./types";

export const WorkBody = ({ children }: WorkBodyProps) => {
  return <StyledWorkBody>{children}</StyledWorkBody>;
};

const StyledWorkBody = styled.div`
  display: flex;
  overflow-y: hidden;
  height: 100%;
  align-items: stretch;
  @media (max-width: ${deviceSize.tablet}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }
`;
