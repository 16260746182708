import { Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconTrashOff } from "@tabler/icons-react";
import { DefectAutoComplete } from "features/autoComplete/defect/DefectAutoComplete";
import { BoxNumberInput } from "features/work/boxNumberInput";
import BoxPlusFormButton from "features/work/boxPlusformButton";
import { WorkButton as DefectButton } from "features/work/workButton";
import { WorkTitle } from "features/work/workTitle";
import { useEffect, useState } from "react";
import { theme } from "styles/theme";
import { DefectObject } from "../types";
import { ReportBoxMain } from "./reportBoxMain";
import { DefectReportBoxProps } from "./types";

const ReportBox = Object.assign(ReportBoxMain, {
  DefectTitle: WorkTitle,
  AutoComplete: DefectAutoComplete,
  Input: BoxNumberInput,
  PlusFormButton: BoxPlusFormButton,
  DefectButton: DefectButton,
});

export const WorkDefectReportBox = (params: DefectReportBoxProps) => {
  const [defectCode, setDefectCode] = useState<string | null>("");
  const { value, onSubmit } = params;

  const form = useForm({
    initialValues: {
      defects: {} as DefectObject,
    },
  });

  useEffect(() => {
    if (value) {
      setDefectCode(null);
      form.setValues({
        defects: Object.entries(value as DefectObject).reduce((acc, [key, value]): any => {
          return {
            ...acc,
            [key]: {
              ...value,
              defectQuantity: 0
            },
          };
        }, {} as DefectObject)
      });
    }
  }, [value]);

  return (
    <ReportBox>
      <ReportBox.DefectTitle
        mt={"3rem"}
        size={"3rem"}
        c={"#FFFFFF"}
        weight={700}
        lh={"130%"}
        mb={30}
      >
        {"불량보고"}
      </ReportBox.DefectTitle>
      <Flex align={"center"} justify={"flex-end"} gap={'0.5rem'}>
        <ReportBox.AutoComplete
          value={defectCode}
          size="lg"
          styles={{
            input: {
              width: "23rem",
              height: "4.5rem",
              borderRadius: "0.5rem",
            },
          }}
          onChange={(defectCode: string | null) => {
            setDefectCode(defectCode)
          }}
        />
        <ReportBox.Input
          size="md"
          styles={{
            input: {
              width: "10rem",
              height: "4.5rem",
              borderRadius: "0.5rem",
            },
          }}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
          value={Number(form.values.defects?.[defectCode || ""]?.defectQuantity)}
        />
        <ReportBox.PlusFormButton
          bg={'cyan'}
          w="6rem"
          h="4.5rem"
          num={1}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <ReportBox.PlusFormButton
          bg={'indigo'}
          w="6rem"
          h="4.5rem"
          num={5}
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <ReportBox.DefectButton
          disabled={!defectCode}
          onClick={() => {
            onSubmit && onSubmit({
              ...form.values.defects[defectCode as string],
              defectCode: defectCode as string
            });
          }}
          type={"submit"}
          bg={theme?.colors?.red?.[6]}
          rightIcon={<IconTrashOff size="2.5rem" />}
        >
          {"불량 보고"}
        </ReportBox.DefectButton>
      </Flex>
    </ReportBox >
  );
};