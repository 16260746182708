import { DefaultApiWmsFlushPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { GET_INVENTORIES_QUERY_KEY } from "api/inventories/useInventoriesGetQuery";

import DefaultInstance from "instance/axios";
import { useMutation, useQueryClient } from "react-query";

const fetchwmsFlushPut = ({
    wmsFlushPutRequest
}: DefaultApiWmsFlushPutRequest) =>
    DefaultInstance.wmsFlushPut({
        wmsFlushPutRequest
    });

export const useWmsFlushPut = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({
            wmsFlushPutRequest
        }: DefaultApiWmsFlushPutRequest) =>
            fetchwmsFlushPut({ wmsFlushPutRequest }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
            },
        }
    );
};
export default useWmsFlushPut;
