import { OperationOutsourcesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import DefaultInstance from "instance/axios";
import { useQuery } from "react-query";

export const GET_OUTSOURCES_QUERY_KEY =
  "get-operationOutsources-operationOutsource";

const getOperationOutsourcesOperationOutsource = (params?: any) => {
  return DefaultInstance.operationOutsourcesOperationOutsourceIdGet({
    operationOutsourceId: params?.operationOutsourceId,
  });
};

const useOperationOutsourcesOperationOutGetQuery = (
  params?: {
    operationOutsourceId: number | undefined;
  },
  options?: {
    onSuccess: (data: any) => void;
  }
) => {
  return useQuery<
    AxiosResponse<OperationOutsourcesGet200ResponseRowsInner, any[]>
  >({
    queryKey: [GET_OUTSOURCES_QUERY_KEY],
    enabled: !!params?.operationOutsourceId,
    queryFn: () =>
      params
        ? getOperationOutsourcesOperationOutsource(params)
        : getOperationOutsourcesOperationOutsource(),
  });
};

export default useOperationOutsourcesOperationOutGetQuery;
