interface WorkThProps {
  children: React.ReactNode;
  height?: number;
  width?: number;
  align?: "left" | "center" | "right";
  thColor?: string;
}

export const TableRow = (params: WorkThProps) => {
  const { children, height, width, align, thColor, ...etcParams } = params;
  return (
    <tr
      style={{
        background: "inherit",
        color: thColor || "#FFFFFF",
        display: "flex",
      }}
      {...etcParams}
    >
      {children}
    </tr>
  );
};
