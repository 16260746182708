export const WarningPopPageInfo = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: "red",
        fontSize: "5rem",
        whiteSpace: "pre-line",
      }}
    >
      "선택된 작업지시가 없습니다. <br /> 작업지시서에서 먼저 선택해주시기
      바랍니다."
    </div>
  );
};
