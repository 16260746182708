interface WorkThProps {
  children: React.ReactNode;
  height?: number;
  width?: number;
  align?: "left" | "center" | "right";
  flex?: number;
}

export const TableData = (params: WorkThProps) => {
  const { children, height, width, align, flex } = params;
  const calculatedWidth = `${String(children).length * 6}px`; // 길이에 따른 width 계산
  
  return (
    <td
      style={{
        fontSize: "1.8rem",
        padding : "0.5rem 0.625rem",
        flex : `${flex}`,
      }}
      {...params}
    >
      <div
        style={{
          width: "100%",
          textAlign:
            typeof children === "number" || !isNaN(Number(children))
              ? "right"
              : "left",
        }}
      >
        {typeof children === "number" ||
          (typeof children === "number" || !isNaN(Number(children))
            ? Number(children).toLocaleString()
            : children)}
        {typeof children === "number" || (!isNaN(Number(children)))}
      </div>
    </td>
  );
};
