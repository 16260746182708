import dayjs from "dayjs";

// 요일별 날짜 오프셋 -> 금요일을 바라본다.
// 0: 일요일, 1: 월요일, 6: 토요일
const dayOffset: { [key: number]: number } = {
    0: -2, // Sunday
    1: -3, // Monday
    6: -1, // Saturday
};

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.03.29 (금)
 * 작성내용 : 직전 작업일(영업일)과 오늘 날짜를 배열 형태로 반환한다.
 *  예시 )   오늘이 토요일(23일) 이라면 -> 날짜 기간 = 2024-03-22 ~ 2024-03-23
            오늘이 일요일(24일) 이라면 -> 날짜 기간 = 2024-03-22 ~ 2024-03-24
            오늘이 월요일(25일) 이라면 -> 날짜 기간 = 2024-03-22 ~ 2024-03-25
 * @returns [직전 작업일, 오늘 날짜]
 */
export const getBeforeWorkingDayAndToday = (): [Date, Date] => {
    const today = dayjs();
    const dayOfWeek = today.day();
    const offset = dayOffset[dayOfWeek] ?? -1;

    return [today.subtract(-offset, 'day').toDate(), today.toDate()];
}