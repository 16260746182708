import { Title, TitleProps } from "@mantine/core";

export const WorkTitle = (params: TitleProps) => {
  const { children } = params;
  return (
    <Title color="#868E96" align="left" {...params}>
      {children}
    </Title>
  );
};
