import styled from "@emotion/styled";
import { WorkMainProps } from "./types";

export const WorkMain = (params: WorkMainProps) => {
  const { children } = params;

  return <StyledWorkMain>{children}</StyledWorkMain>;
};

const StyledWorkMain = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
