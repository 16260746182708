import { Box, BoxProps } from "@mantine/core";
import { theme } from "styles/theme";

export const WorkBox = (params: BoxProps) => {
  const { children } = params;

  return (
    <Box
      style={{
        borderRadius: "2px",
        background: theme.colors?.red?.[6],
        display: "flex",
        padding: "1px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        color: theme.colors?.white?.[0],
      }}
    >
      {children}
    </Box>
  );
};
