import { Badge, Button, Flex, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconTrash } from "@tabler/icons-react";
import useWorkLogsWorkLogIdCancelDefectPut from "api/cancelDefect/useWorkLogsWorkLogIdCancelDefectPut";
import useWorkLogsWorkLogIdCancelPerformancePut from "api/cancelPerformance/useCancelPerformance";
import useCancelInputPutMutation from "api/input/useCancelInputPutMutation";
import { useWorkLogsGet } from "api/logs/useWorkLogsGetQuery";
import { getColorOfLogType, getKoreanWorkLogType } from "constants/workLogType";
import dayjs from "dayjs";
import { WorkTable } from "features/work/components/table/workTable";
import { WorkFlexBox } from "features/work/workFlexBox";
import { WorkText } from "features/work/workText";
import { WorkTitle } from "features/work/workTitle";
import { useState } from "react";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";
import { LogsFormProps } from "./types";


const Logs = Object.assign({}, FormMain, {
    Button: Button,
    Description: WorkTitle,
    Text: WorkText,
    Table: WorkTable,
    Flex: WorkFlexBox,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Pagination: Pagination
});

export function LogsForm(params: LogsFormProps) {

    const { workId } = params;
    const [activePage, setPage] = useState<number>(1);

    const { mutate: cancelPerformance } = useWorkLogsWorkLogIdCancelPerformancePut()
    const { mutate: cancelInput } = useCancelInputPutMutation("cancel");
    const { mutate: cancelDefect } = useWorkLogsWorkLogIdCancelDefectPut();

    const { data: WorkLog } = useWorkLogsGet({
        query: {
            $and: [{ workId: { $eq: workId ?? 0 } },
            { parentWorkLogId: { $eq: null } },
            {
                $or: [
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE } },
                ],
            },
            ]
        },
        sort: "-id",
        page: activePage,
    })

    const onCancel = ({ worLogId, logType }: { worLogId: number, logType: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum }) => {

        if (!window.confirm('해당 작업을 취소하시겠습니까?')) {
            return;
        }

        switch (logType) {
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE:
                cancelPerformance({
                    workLogId: worLogId,
                    workLogsWorkLogIdCancelInputPutRequest: {
                        createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                break;
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT:
                cancelDefect({
                    workLogId: worLogId
                })
                break;
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT:
                cancelInput({
                    workLogId: worLogId
                })
                break;
            default:
                break;
        }
    }

    return (
        <Logs.Wrapper>
            <Logs.Description c={'#010000'} size={'sm'} fw={400}>
                {'작업 로그(투입, 실적, 불량)를 확인합니다.'}
            </Logs.Description>
            <Logs.Description c={'#000000'} size={'sm'} fw={400}>
                {'취소 버튼을 클릭하여 특정 작업을 취소할 수 있습니다.'}
            </Logs.Description>
            <Logs.Table
                fontSize={'sm'}
                withBorder={false}
                withColumnBorders={false}
                theadColor={"#FFFFFF"}
                flex={[1, 1, 2, 1, 2, 1, 1, 1]}
                trColor={"#000000"}
                columns={["로그 번호", "연관 로그 번호", "일시", "타입", "로트이름", "수량", "작업자", "Action"]}
                data={WorkLog?.rows?.map((log) => {

                    const logType = getKoreanWorkLogType(log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum)
                    const colorOfLogType = getColorOfLogType(log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum)
                    const lotName = log?.workLogType === "INPUT"
                        ? (log.logData?.incoming?.lotData?.name !== null ? log.logData?.incoming?.lotData?.name : '-')
                        : undefined;

                    return [
                        <Logs.Text align="right" c={'dark'} size={'xl'}>{log?.id ?? '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{log.parentWorkLogId ?? '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{dayjs(log?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Logs.Text>,
                        <Badge size="xl" color={colorOfLogType}>{logType}</Badge>,
                        <Logs.Text c={'dark'} size={'xl'}>{lotName}</Logs.Text>,
                        <Logs.Text align="right" c={'dark'} size={'xl'}>{log?.quantity ? `${log?.quantity} ${log.unitText}` : '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{log?.creatorUserName}</Logs.Text>,
                        log.alreadyCanceledWorkLog ?
                            <Logs.Text c={'grape'} size={'xl'} ta={'center'}>취소됨</Logs.Text>
                            :
                            <Logs.Button
                                bg={"inherit"}
                                styles={{
                                    'root': {
                                        '&:hover': {
                                            background: 'inherit'
                                        }
                                    }
                                }}
                                onClick={() => onCancel({
                                    worLogId: log.id as number,
                                    logType: log.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
                                })}>

                                <IconTrash color="red" />
                                <Logs.Text pl={'1rem'} c={'red'} size={'xl'}>취소</Logs.Text>
                            </Logs.Button>
                    ] as string[] | React.ReactElement[];
                })}
            />
            <Flex justify={'center'}>
                <Logs.Pagination
                    size={'xl'}
                    value={activePage}
                    onChange={setPage}
                    total={WorkLog?.totalPages as number}
                />
            </Flex>
        </Logs.Wrapper>
    );
}
