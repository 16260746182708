import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, forwardRef, useState } from "react";

import styled from "@emotion/styled";
import {
  ItemOption,
  useItemsAutoComplete,
  useItemsCode,
} from "./auto-useGetItemsQuery";

import { IconX } from "@tabler/icons-react";
import { useModal } from "context/ModalStackManager";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface ItemAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

// 입력값: value (item 모델의 code)
// 출력값: onChange (item 모델의 code)

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ group, label: name, value: code, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Flex direction="row" justify="space-between" align="center">
        <Flex align={"center"}>
          <Text>{name}</Text>
          <Text fz="xs">(품번: {code})</Text>
        </Flex>
        <Text fz="xs">{group}</Text>
      </Flex>
    </div>
  )
);

export const ItemAutoComplete = (params: ItemAutoCompleteProps) => {
  const {
    value: itemCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useItemsAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useItemsCode(!!itemCode, itemCode ?? null);

  const selectOptions = [...(options ?? []), ...(initialOptions ?? [])].reduce(
    (unique: ItemProps[], option: ItemProps) => {
      return unique.some((u) => u.value === option.value)
        ? unique
        : [...unique, option];
    },
    []
  );

  let selectedItem = initialOptions?.find((item) => item.value === itemCode);
  const onChangeHandler = (e: string | null) => {
    selectedItem = options?.find((item) => item.value === e);
    onChange?.(e);
  };

  return (
    <Select
      styles={{
        input: {
          width: width,
        },
      }}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      value={itemCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={selectOptions}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={ItemInfo({
        item: selectedItem as ItemOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      {...etcParams}
    />
  );
};

const ItemInfo = (params: {
  item?: ItemOption;
  onChange: (itemCode: string | null) => void;
}) => {
  const { item, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return item?.value ? (
    <ItemInfoLabel>
      ({item.value}) <IconX size="1rem" onClick={clearHandler} />{" "}
    </ItemInfoLabel>
  ) : null;
};

const AddNewItem = (params: {
  itemName: string;
  onChange: (itemCode: string) => void;
}) => {
  const { itemName, onChange } = params;
  const { openModal } = useModal();

  const onCloseHandler: EventHandler<any> = (values) => {};
};
const ItemInfoLabel = styled.div`
  font-size: 8px;
  color: #666666;
  padding-right: 8px;
`;
