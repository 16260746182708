import dayjs from "dayjs";

const timeUtil = (utcTimeString: string | undefined) => {
  if (!utcTimeString) return "";

  const curr = new Date(utcTimeString);
  // 2. UTC 시간 계산
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

  // 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000; //한국 시간(KST)은 UTC시간보다 9시간 더 빠르므로 9시간을 밀리초 단위로 변환.

  const kr_curr = new Date(utc + KR_TIME_DIFF);

  const timeUtil = `${kr_curr.getFullYear()}-${String(
    kr_curr.getMonth() + 1
  ).padStart(2, "0")}-${String(kr_curr.getDate()).padStart(2, "0")} ${String(kr_curr.getHours()).padStart(2, "0")}:${String(kr_curr.getMinutes()).padStart(2, "0")}`;

  return timeUtil;
};

export default timeUtil;

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.05.23 (목)
 * 날짜 데이터 포맷팅 (YYYY-MM-DD HH:mm:ss)
 * @param date 날짜 또는 시간 문자열
 * @returns
 */
export const setDateFormatStringWithTime = (
  date: Date | string | undefined
) => {
  if (date === null || date === undefined) return "";
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
};