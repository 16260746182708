import { Text, TextProps } from "@mantine/core";

export const WorkText = (params: TextProps) => {
  const { children } = params;

  return (
    <Text size={"2rem"} color="#E9ECEF" {...params}>
      {typeof children === "number" || !isNaN(Number(children))
        ? Number(children).toLocaleString()
        : children}
    </Text>
  );
};