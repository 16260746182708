import { Flex, FlexProps } from "@mantine/core";

export const WorkFlexBox = (params: FlexProps) => {
  const { children } = params;

  return (
    <Flex direction={"column"} gap={8} align={"flex-start"} {...params}>
      {children}
    </Flex>
  );
};
