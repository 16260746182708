import styled from "@emotion/styled";
import { deviceSize } from "styles/theme";
import { ReportBoxProps } from "./types";
export const ReportBoxMain = ({ children }: ReportBoxProps) => {
  return <StyledReportBoxMain>{children}</StyledReportBoxMain>;
};

const StyledReportBoxMain = styled.div`
  width: 100%;
  @media (max-width: ${deviceSize.tablet}) {
    margin-bottom: 60px;
  }
`;
