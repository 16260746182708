import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";

import { useDefectsGet } from "api/defects/useDefectsGetQuery";
import useGetLotsLotIdQuery from "api/lots/useGetLotsLotIdQuery";
import { useWorksWorkIdGet } from "api/work/useWorksWorkIdGetQuery";
import { UseGetWorkDataProps } from "./types";

export const useGetWorkData = ({ workId }: UseGetWorkDataProps) => {
  const { data: defectData } = useDefectsGet();
  const { data: workData } = useWorksWorkIdGet({
    workId: Number(workId),
    defectCodes: defectData?.rows?.map((defect) => defect.code) as string[],
  });
  const { data: lotData } = useGetLotsLotIdQuery({ lotId: workData?.productionPlan?.lastLotId as number });

  const { data: workInventoriesData, refetch: workInventoriesRefetch } = useInventoriesGetQuery({
    query: {
      $and: [
        {
          itemCode: workData?.operationData?.operationBoms?.map(
            (bom) => bom.itemCode
          ),
        },
        { locationCode: workData?.equipment?.toLocationCode ?? workData?.operationData?.routing?.toLocationCode },
        { quantity: { $ne: 0 } },
      ],
    },
  });

  return { workData, workInventoriesData, lotData, workInventoriesRefetch };
};
