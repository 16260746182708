import styled from "@emotion/styled";
import { Text } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useLocalStorage } from "context/LocalStorageProvider";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { theme } from "styles/theme";
import { setToLocaleString } from "utils/unitMark";

interface WorkRowProps {
  data: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const WorkRow = (params: WorkRowProps) => {
  const {
    operationCode,
    targetQuantity,
    trackingStatus,
    equipment,
    item,
    scheduleSeq,
    createdAt,
    scheduledAt,
    erpWorkOrderSerl
  } = params.data;

  const navigate = useNavigate();
  const { setWorkId } = useLocalStorage();

  const onClickMemorize = (
    work: ProductionPlansGet200ResponseRowsInnerWorksInner
  ) => {
    setWorkId(work.id)
  };

  const onClickNavigate = (
    work: ProductionPlansGet200ResponseRowsInnerWorksInner
  ) => {
    navigate(`/work/${work.id}`);
  };

  return (
    <WorkRowWrapper
      color="transparent"
      onClick={() => {
        onClickMemorize(params.data);
        onClickNavigate(params.data);
      }}
    >
      <Td seq={1}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {scheduleSeq}
        </Text>
      </Td>
      <Td seq={2} flex={2}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {equipment?.name}
        </Text>
      </Td>
      <Td seq={3} flex={2}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {item?.name}({operationCode})
        </Text>
      </Td>
      <Td seq={4}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {setToLocaleString(targetQuantity)} {item?.unitText}
        </Text>
      </Td>
      <Td seq={5} flex={2}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {item?.spec ?? '-'}
        </Text>
      </Td>
      <Td seq={6} flex={2}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {dayjs(scheduledAt).format("YYYY-MM-DD")}
        </Text>
      </Td>
      <Td seq={7}>
        <Text c={"#FFFFFF"} size={"lg"} align="left" w={"100%"}>
          {trackingStatus === "WAITING"
            ? "대기"
            : trackingStatus === "PAUSED"
              ? "일시정지"
              : trackingStatus === "WORKING"
                ? "진행중"
                : "완료"}
        </Text>
      </Td>
      <Td seq={8}>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {erpWorkOrderSerl}
        </Text>
      </Td>
    </WorkRowWrapper>
  );
};

const WorkRowWrapper = styled.tr<{ color: string }>`
  box-sizing: border-box;
  display: flex;
  // flex-direction: column;
  flex-direction: row;
  background-color: ${(props) => props.color};
  gap: 0px;
  align-items: flex-start;
  /* justify-content: flex-start; */
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  border-bottom: 1px solid white;
  width: 100%;
  
  &:hover {
    background-color: #999;
    opacity: 0.8;
  }
`;

const Td = styled.td<{ flex?: number; seq?: number }>`
  flex : ${(props) => (props.flex ? props.flex : 1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  /* border: 0.0625rem solid ${theme.colors?.gray?.[3]}; */
`;
