import axios from "axios";
import { authEnum } from "config/auth";
import { useLocalStorage } from "context/LocalStorageProvider";
import { UserInstance } from "instance/axios";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { FooterInfo } from "recoil/layoutAtom";
import deCodeToken from "utils/decodeToken";
import { authTokenAtom } from "../../../recoil/authAtom";

type USER_ROLES = "ADMIN" | "CENTER_ADMIN" | "TECHNICIAN" | "ADVISER";

function useGetAuthToken(
  loginInform: { identifier: string; password: string },
  loginCallback: ({
    centerName,
    managerName,
    workbayName,
  }: Partial<FooterInfo>) => void
) {
  const queryClient = useQueryClient();
  const setRecoilAuthToken = useSetRecoilState(authTokenAtom);
  const navigate = useNavigate();
  const { setUserId, setUserName, setEquipmentCode, setOperationCode, setManagementRole, setName, setLocationCode } = useLocalStorage();
  const { isLoading: isLoginLoading, refetch: loginRefetch } = useQuery({
    queryKey: ["authToken", loginInform],
    queryFn: () =>
      UserInstance.authLoginPost(
        {
          authLoginPostRequest: {
            identifier: loginInform.identifier,
            password: loginInform.password,
          },
        },
        {
          headers: {
            Authorization: undefined,
          },
        }
      ),
    staleTime: 0,
    cacheTime: 0,
    enabled: false,
    onSuccess: async (data) => {
      const authToken = data.data?.token;
      const userInform = deCodeToken(authToken);
      setUserId(userInform.user.id); // pk
      setUserName(userInform.user.username); // 아이디
      setName(userInform.user.name); // 이름
      setOperationCode('-')
      // localStorage.setItem("userId", userInform.user.id);
      // localStorage.setItem("userName", userInform.user.username);
      localStorage.setItem(authEnum.authToken, authToken);
      setRecoilAuthToken(authToken);

      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth/whoami`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // localStorage.setItem("equipmentCode", result.data.equipmentCode);
      setEquipmentCode(result.data.equipmentCode);
      setLocationCode(result.data.locationCode);
      // 이거 관련해서 util로 만들자
      if (result?.data?.permissions?.allow) {
        const roles = result.data.permissions.allow.map((role: string) => {
          if (role.includes('update')) return 'UPDATE';
          else if (role.includes('create')) return 'CREATE';
          else if (role.includes('delete')) return 'DELETE';
          else if (role.includes('read')) return 'READ';
          else return 'READ';
        }).join(',');
        setManagementRole(roles);
      } else {
        setManagementRole(undefined);
      }
      navigate("/works");
    },
    onError(data: any) {
      if (isLoginLoading) {
        alert(data.response.data.message);
      }
    },
  });

  return {
    isLoginLoading,
    loginRefetch,
  };
}

export default useGetAuthToken;
