import {
  ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { GET_INVENTORIES_QUERY_KEY } from "api/inventories/useInventoriesGetQuery";

import { WORK_LOG_GET_KEY } from "api/logs/useWorkLogsGetQuery";
import { WORKS_WORK_ID_GET_KEY } from "api/work/useWorksWorkIdGetQuery";

import { TrackingInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";

export const PRODUCTION_TRACKING_ACTION_API = "ProductionTimeTrackingActionApi";

interface StartParams
  extends ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest {}
interface EndParams
  extends ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest {}
interface PauseParams
  extends ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest {}
interface ResumeParams
  extends ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest {}

type MutationType = "start" | "end" | "pause" | "resume";

type Params<T extends MutationType> = T extends "start"
  ? StartParams
  : T extends "end"
  ? EndParams
  : T extends "pause"
  ? PauseParams
  : ResumeParams;

const startFetcher = (params: StartParams) =>
  TrackingInstance.worksWorkIdTrackingStartPost(params);

const endFetcher = (params: EndParams) =>
  TrackingInstance.worksWorkIdTrackingEndPost(params);

const pauseFetcher = (params: PauseParams) =>
  TrackingInstance.worksWorkIdTrackingPausePost(params);

const resumeFetcher = (params: ResumeParams) =>
  TrackingInstance.worksWorkIdTrackingResumePost(params);

const useProductionTrackingActionMutationQuery = <T extends MutationType>(
  type: T
) => {
  const queryClient = useQueryClient();

  const startMutation = useMutation(
    (params: Params<typeof type>) => startFetcher(params as StartParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WORK_LOG_GET_KEY);
        queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const endMutation = useMutation(
    (params: Params<typeof type>) => endFetcher(params as EndParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WORK_LOG_GET_KEY);
        queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const pauseMutation = useMutation(
    (params: Params<typeof type>) => pauseFetcher(params as PauseParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WORK_LOG_GET_KEY);
        queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  const resumeMutation = useMutation(
    (params: Params<typeof type>) => resumeFetcher(params as ResumeParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WORK_LOG_GET_KEY);
        queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
        queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
      },
    }
  );

  switch (type) {
    case "start":
      return startMutation;
    case "end":
      return endMutation;
    case "pause":
      return pauseMutation;
    case "resume":
      return resumeMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useProductionTrackingActionMutationQuery;
