import { DefaultApiWorksWorkIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import DefaultInstance from "instance/axios";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";
import { WORKS_WORK_ID_GET_KEY } from "./useWorksWorkIdGetQuery";

const fetchWorksWorkIdSettingPut = ({
  workId,
  worksWorkIdDeleteRequest,
}: DefaultApiWorksWorkIdPutRequest) =>
  DefaultInstance.worksWorkIdPut({
    workId,
    worksWorkIdDeleteRequest,
  });

export const useWorksWorkIdPutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ workId, worksWorkIdDeleteRequest }: DefaultApiWorksWorkIdPutRequest) =>
      fetchWorksWorkIdSettingPut({ workId, worksWorkIdDeleteRequest }),
    {
      onSuccess: () => {
        customNotification.success({
          message: "세팅 설정이 완료 되었습니다.",
        });
        queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
      },
    }
  );
};
export default useWorksWorkIdPutMutation;
