import { notifications } from "@mantine/notifications";

export const customNotification = {
  error: ({ message }: { message: string }) => {
    notifications.show({
      title: "실패하였습니다.",
      message: message,
      styles: (theme) => ({
        root: {
          borderColor: theme.colors.red[6],
          "&::before": { backgroundColor: theme.colors.red[6] },
        },

        title: { color: theme.black },
        description: { color: theme.black },
        closeButton: {
          color: theme.black,
          "&:hover": { backgroundColor: theme.colors.red[7] },
        },
      }),
    });
  },
  success: ({ message }: { message: string }) => {
    notifications.show({
      title: "성공하였습니다.",
      message: message,
      styles: (theme) => ({
        root: {
          borderColor: theme.colors.green[6],
          "&::before": { backgroundColor: theme.colors.green[6] },
        },
        title: { color: theme.black },
        description: { color: theme.black },
        closeButton: {
          color: theme.black,
          "&:hover": { backgroundColor: theme.colors.green[7] },
        },
      }),
    });
  },
};
