import { notifications } from "@mantine/notifications";
import { ProductionActionApiWorksWorkIdPerformancePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { GET_INVENTORIES_QUERY_KEY } from "api/inventories/useInventoriesGetQuery";
import { WORKS_WORK_ID_GET_KEY } from "api/work/useWorksWorkIdGetQuery";
import { ProductionInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";

const fetchWorksWorkIdPerformancePut = ({
    workId,
    worksWorkIdPerformancePutRequest,
}: ProductionActionApiWorksWorkIdPerformancePutRequest) =>
    ProductionInstance.worksWorkIdPerformancePut({
        workId,
        worksWorkIdPerformancePutRequest
    })

export const useWorksWorkIdPerformancePut = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({
            workId,
            worksWorkIdPerformancePutRequest,
        }: ProductionActionApiWorksWorkIdPerformancePutRequest) =>
            fetchWorksWorkIdPerformancePut({ workId, worksWorkIdPerformancePutRequest }),
        {
            onSuccess: () => {
                notifications.show({
                    title: "실적 보고",
                    message: "생산실적이 성공적으로 저장되었습니다.",
                });
                queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
                queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
            },
        }
    );
};
export default useWorksWorkIdPerformancePut;
