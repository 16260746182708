import { useQuery, useQueryClient } from "react-query";

import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { WORK_LOG_GET_KEY } from "api/logs/useWorkLogsGetQuery";
import { AxiosResponse } from "axios";
import DefaultInstance from "instance/axios";

export const WORKS_WORK_ID_GET_KEY = "pop-get-work";

const fetchWorksWorkIdGet = (workId: number, defectCodes: string[]) =>
  DefaultInstance.worksWorkIdGet(
    {
      workId,
    },
    {
      params: {
        defectCodes: defectCodes,
        populate: [
          "summary",
          "item",
          "equipment",
          "operationData",
          "productionPlan",
          "locationSetting",
        ],
      },
    }
  );

export const useWorksWorkIdGet = (
  { workId, defectCodes = [] }: { workId: number; defectCodes: string[] },
  options?: {
    onSuccess: (work: ProductionPlansGet200ResponseRowsInnerWorksInner) => void;
  }
) => {
  const queryClient = useQueryClient();

  return useQuery(
    [WORKS_WORK_ID_GET_KEY, workId],
    () => fetchWorksWorkIdGet(workId, defectCodes),
    {
      select: (
        data: AxiosResponse<
          ProductionPlansGet200ResponseRowsInnerWorksInner,
          any
        >
      ) => data.data as ProductionPlansGet200ResponseRowsInnerWorksInner,
      onSuccess: () => {
        queryClient.invalidateQueries(WORK_LOG_GET_KEY);
      },
      enabled: !!(defectCodes.length > 0 && workId),
    }
  );
};
