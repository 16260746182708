import { notifications } from "@mantine/notifications";
import { ProductionActionApiWorksWorkIdDefectPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { WORKS_WORK_ID_GET_KEY } from "api/work/useWorksWorkIdGetQuery";
import { ProductionInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";

const fetchWorksWorkIdDefectPut = ({
  workId,
  worksWorkIdDefectPutRequest,
}: ProductionActionApiWorksWorkIdDefectPutRequest) =>
  ProductionInstance.worksWorkIdDefectPut({
    workId,
    worksWorkIdDefectPutRequest,
  });

export const useWorksWorkIdDefectPut = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      workId,
      worksWorkIdDefectPutRequest,
    }: ProductionActionApiWorksWorkIdDefectPutRequest) =>
      fetchWorksWorkIdDefectPut({ workId, worksWorkIdDefectPutRequest }),
    {
      onSuccess: () => {
        notifications.show({
          title: "불량 보고",
          message: "성공적으로 등록되었습니다.",
        });
        queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
      },
    }
  );
};
export default useWorksWorkIdDefectPut;
