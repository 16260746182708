import { InspectionsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { SpcInstance } from "instance/axios";
import { QueryClient, useQuery } from "react-query";

export const GET_INSPEC_QUERY_KEY = "get-spc-inspections";

const getSpcInspectionsFetch = (params?: any) => {
  if (params) {
    const queryString = JSON.stringify(params.query);
    return SpcInstance.inspectionsGet({
      query: queryString && [queryString],
      search: params.search,
      searchFields: params.searchFields,
      page: params.activePage ?? 1,
      pageSize: 10,
      sort: params.sort,
      populate: params.populate,
    });
  } else return SpcInstance.inspectionsGet();
};

const useSpcInspectionsGetQuery = (
  params?: any,
  options?: {
    onSuccess: (data: any) => void;
  }
) => {
  return useQuery<AxiosResponse<InspectionsGet200Response, any[]>>({
    queryKey: params
      ? [
        GET_INSPEC_QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
        params.searchFields,
        params.search,
      ]
      : [GET_INSPEC_QUERY_KEY],
    enabled: !!params,
    queryFn: () =>
      params ? getSpcInspectionsFetch(params) : getSpcInspectionsFetch(),
    onSuccess: () => {
      const queryClient = new QueryClient();
      queryClient.invalidateQueries(GET_INSPEC_QUERY_KEY);
    }
  });
};

export default useSpcInspectionsGetQuery;
