import { Accordion, Badge, Divider, Flex, Highlight, Text } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum,
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
} from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconArticle,
  IconClipboardCheck,
  IconPlayerPause,
  IconPlayerPlay,
  IconPlus,
  IconRefreshDot,
  IconSquareOff
} from "@tabler/icons-react";
import useWorksWorkIdDefectPut from "api/defects/useWorksWorkIdDefectPutMutation";
import { useWorkLogsFindPost } from "api/logs/useWorkLogsFindPostQuery";
import useOperationOutsourcesOperationOutGetQuery from "api/operationOutsources/useOperationOutsourcesOperationOutGetQuery";
import useWorksWorkIdPutMutation from "api/work/useWorksWorkIdPutMutation";
import useProductionTrackingActionMutationQuery from "api/workTracking/useWorkTarckingQuery";
import BigNumber from "bignumber.js";
import { useModal } from "context/ModalStackManager";
import dayjs from "dayjs";
import { DownTimeReasonsForm } from "features/form/downtimeReasons/DownTimeReasonsForm";
import { InspectForm } from "features/form/work/InspectForm";

import { WorkBody } from "features/work/components/layout/workBody";
import { WorkHeader } from "features/work/components/layout/workHeader";
import { WorkMain } from "features/work/components/layout/workMain";
import { WorkLeftSection } from "features/work/components/section/workLeftSection";
import { WorkRightSection } from "features/work/components/section/workRightSection";
import { DefectObject, DefectObjectProps } from "features/work/components/types";

import { WorkTable } from "features/work/components/table/workTable";
import { WorkButton } from "features/work/workButton";
import { WorkFlexBox } from "features/work/workFlexBox";
import WorkProgress from "features/work/workProgress";
import { WorkText } from "features/work/workText";
import { WorkTitle } from "features/work/workTitle";
import { useGetEquipmentCounterBySetting } from "hooks/useGetEquipmentCounterBySetting";
import { useGetWorkData } from "hooks/useGetWorkData";

import { usePutCorrection } from "api/equipmentCorrection/equipmentCorrection";
import useWmsFlushPut from "api/flush/useFlush";
import useCancelInputPutMutation from "api/input/useCancelInputPutMutation";
import useProductionPlansProductionPlanIdSplitProductLotPost from "api/splitProductLot/useSplitProductLot";
import useWorksWorkIdPerformancePut from "api/workPerformance/useWorkPerformance";
import { useLocalStorage } from "context/LocalStorageProvider";
import { Confirm } from "features/confirm";
import { LogsForm } from "features/form/logs/logsForm";
import { RawMaterialInputForm } from "features/form/material/RawMaterialForm";
import { WorkDefectReportBox } from "features/work/components/box/workDefectReportBox";
import { WorkEndReportBox } from "features/work/components/box/workEndReportBox";
import { WorkBox } from "features/work/workBox";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { theme } from "styles/theme";
import { getWorkTrackingStatus } from "utils/tranferWorkStatus";
import { setToLocaleString } from "utils/unitMark";

type ExtendedWorkLogs = WorkLogsGet200ResponseRowsInner & { endTime?: string };

const Work = Object.assign(WorkMain, {
  WorkTitle: WorkTitle,
  WorkText: WorkText,
  WorkFlexBox: WorkFlexBox,
  WorkProgress: WorkProgress,
  WorkTable: WorkTable,
  WorkLeftSection: WorkLeftSection,
  WorkRightSection: WorkRightSection,
  WorkHeader: WorkHeader,
  WorkBody: WorkBody,
  WorkButton: WorkButton,
  WorkDefectReportBox: WorkDefectReportBox,
  WorkEndReportBox: WorkEndReportBox,
  WorkBox: WorkBox,
});

interface LotSummary {
  quantity: number;
  unitText: string;
}
// interface ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner {
//   [key: string]: any; // 문자열 인덱스 시그니처 추가

// }
export const WorkPage = () => {

  const { setOperationCode } = useLocalStorage();

  const {
    TIME_TRACKING_START,
    TIME_TRACKING_END,
    TIME_TRACKING_PAUSE,
    TIME_TRACKING_RESUME,
  } = WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum;

  const { workId } = useParams();
  const { openModal, closeModal } = useModal();
  const [prevEnd, setPrevEnd] = useState<string>('0');

  const { workData, workInventoriesData, lotData } = useGetWorkData({
    workId: workId as string,
  });

  const { mutate: performanceMutate } = useWorksWorkIdPerformancePut();
  const { mutate: defectMutate } = useWorksWorkIdDefectPut();
  const { mutate: settingMutate } = useWorksWorkIdPutMutation();
  const { mutate: flushMutate } = useWmsFlushPut();
  const { mutate: splitMutate } = useProductionPlansProductionPlanIdSplitProductLotPost();
  const { mutate: correctMutate } = usePutCorrection();


  const { mutate: startTrackingMutate } =
    useProductionTrackingActionMutationQuery("start");

  const { mutate: endTrackingMutate } =
    useProductionTrackingActionMutationQuery("end");

  const { mutate: resumeTrackingMutate } =
    useProductionTrackingActionMutationQuery("resume");

  const [status, color] = getWorkTrackingStatus(workData?.trackingStatus);

  const { mutate: cancelInputMutate } = useCancelInputPutMutation("cancel");

  // 작업시간 추적을 위한 workLogs 데이터
  const { data: workTrackingData } = useWorkLogsFindPost({
    query: {
      $and: [
        {
          workId: workId,
        },
        {
          workLogType: [
            TIME_TRACKING_START,
            TIME_TRACKING_END,
            TIME_TRACKING_PAUSE,
            TIME_TRACKING_RESUME,
          ],
        },
      ],
    },
    sort: "createdAt",
  });

  // 투입기록을 위한 workLogs 데이터
  const { data: workInputLogsData } = useWorkLogsFindPost({
    query: {
      $and: [
        {
          workId: workId,
        },
        {
          workLogType: [
            "INPUT",
          ],
        },
      ],
    },
    sort: "-createdAt",
  });

  const { data: outsourceData } = useOperationOutsourcesOperationOutGetQuery({
    operationOutsourceId: workData?.operationOutsourceId,
  });

  const { equipmentCounter, isLimitHit, equipmentLimitCounter } =
    useGetEquipmentCounterBySetting({
      workData: workData as ProductionPlansGet200ResponseRowsInnerWorksInner,
    });

  const setUnitText = workData?.item?.unitText;

  const defectList = Object.keys(workData?.summary?.defect ? workData?.summary?.defect as DefectObject : {}).map((code) => {
    return {
      defectName: (workData?.summary?.defect as DefectObject)[code]?.defectName as string,
      defectCode: code,
      defectQuantity: (workData?.summary?.defect as DefectObject)[code]?.defectQuantity as string,
      setUnitText: setUnitText
    }
  })

  const currentDefectList = Object.keys(workData?.currentLotSummary?.defect ? workData?.currentLotSummary?.defect as DefectObject : {}).map((code) => {
    return {
      defectName: (workData?.currentLotSummary?.defect as DefectObject)[code]?.defectName as string,
      defectCode: code,
      defectQuantity: (workData?.currentLotSummary?.defect as DefectObject)[code]?.actualDefectQuantity as string,
      setUnitText: setUnitText
    }
  })

  let summedByLotName: Record<string, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner> = {};
  if (workInventoriesData?.data?.rows) {
    summedByLotName = workInventoriesData.data.rows.reduce((acc: Record<string, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner>, { lot, quantity, unitText }) => {
      const lotName: string | undefined = lot?.name;
      if (lotName) {
        if (!acc[lotName]) {
          acc[lotName] = { quantity: '0', unitText: unitText || '' };
        }
        acc[lotName].quantity = (Number(acc[lotName].quantity) + Number(quantity)).toString();
      }
      return acc;
    }, {});
  }

  const performMutate = ({ quantity, warnInsufficiency, onSuccess, onError }
    : { quantity: string, warnInsufficiency?: boolean, onSuccess?: () => void, onError?: () => void }) => {
    performanceMutate(
      {
        workId: Number(workId),
        worksWorkIdPerformancePutRequest: {
          quantity,
          warnInsufficiency
        }
      },
      {
        onSuccess: () => onSuccess && onSuccess(),
        onError: () => onError && onError()
      }
    );
  };


  const onSaveEnd = (end: string) => {
    let difference = '0';
    const setType = workData?.externalCounterSetting as ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum
    const isEndGreaterThanPrevEnd = BigNumber(end).isGreaterThan(prevEnd);

    if (setType === 'COUNTER_AND_AUTO_REPORT') {
      difference = BigNumber(end).minus(prevEnd).toString();
      setPrevEnd(end);
    }

    if (end !== '0') {
      if (isEndGreaterThanPrevEnd && setType === 'COUNTER_AND_AUTO_REPORT') {

        performMutate({
          quantity: workData?.summary?.end !== '0' ? difference : end,
          onSuccess: () => closeModal({})
        });

      } else if (setType === 'COUNTER_AND_HANDWRITING' || setType === 'HANDWRITING') {
        if (window.confirm('생산 실적을 저장하시겠습니까?')) {
          performMutate({
            quantity: end,
            warnInsufficiency: true,
            onError: () =>
              openModal(
                <Confirm
                  message={"재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?"}
                  yesCallback={() => {
                    // 재고 보정 확인 모달
                    openModal(
                      <Confirm
                        message={"이전 자재를 사용 완료 하였습니까?"}
                        yesCallback={() => { correctMutate(workData?.equipmentCode as string) }}
                        noCallback={() => { }}
                        commonCallback={() => {
                          closeModal({});
                          openModal(
                            // 원부자재 투입 모달
                            <RawMaterialInputForm workData={workData} onInputSuccess={() => {
                              performMutate({
                                quantity: end,
                                warnInsufficiency: false,
                                onSuccess: () => closeModal({})
                              });
                            }} />, "", "원부자재 투입")
                        }}
                      />,
                      "",
                      "재고 사용 여부 확인"
                    );
                  }}
                  noCallback={() =>
                    performMutate({
                      quantity: end,
                      warnInsufficiency: false,
                      onSuccess: () => closeModal({})
                    })}
                  commonCallback={() => { }}
                />,
                "",
                "생산 가능 재고 부족"
              )
          });
        }
      }
    }
  }

  const onCancelInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.confirm('투입 기록을 취소하시겠습니까?') &&
      cancelInputMutate({
        workLogId: workInputLogsData?.data?.[0]?.id as number,
      });
  }

  const onSaveDefects = async (defect: DefectObjectProps) => {

    if (window.confirm('불량 실적을 저장하시겠습니까?')) {
      const isDefectQuantityGreaterThanEnd =
        BigNumber(BigNumber(defect.defectQuantity as string).plus(workData?.summary?.defectTotal as string))
          .isGreaterThan(workData?.summary?.end as string)

      if (isDefectQuantityGreaterThanEnd) {
        alert('불량 수량이 생산 수량보다 많습니다.')
        return
      }


      const defectMutationOptions = (defect: DefectObjectProps) => ({
        workId: Number(workId),
        worksWorkIdDefectPutRequest: {
          defectCode: defect.defectCode + "",
          quantity: BigNumber(defect.defectQuantity as string).toString(),
        },
      });
      defectMutate(defectMutationOptions(defect));
    }
  }



  return (
    <Work>
      <Work.WorkHeader>
        <Work.WorkFlexBox direction={"row"} p={"12px 16px"} gap={8}>
          <Work.WorkButton
            onClick={() => {
              workData?.equipment?.activeWorkId && openModal(
                <Confirm
                  message={"이전 자재를 사용 완료 하였습니까?"}
                  yesCallback={() => { correctMutate(workData?.equipmentCode as string); alert('재고 보정이 완료되었습니다.') }}
                  noCallback={() => { }}
                  commonCallback={() => { closeModal({}); openModal(<RawMaterialInputForm workData={workData} />, "", "원부자재 투입") }}
                />,
                "",
                "재고 사용 여부 확인"
              );

              // if (workData?.equipment?.activeWorkId && window.confirm('재고 보정을 하시겠습니까?')) {
              //   correctMutate(workData?.equipmentCode as string)
              // }
              // openModal(<RawMaterialInputForm workData={workData} />, "", "원부자재 투입");
            }}
            bg={theme?.colors?.green?.[6]}
            leftIcon={<IconPlus size="2.5rem" />}
          >
            {"원부자재 투입"}
          </Work.WorkButton>
          <Work.WorkButton
            disabled={workData?.trackingStatus === "WAITING"}
            onClick={() => {
              openModal(<InspectForm workData={workData} lotId={lotData?.data.id} />, "", "검사 기록 보고");
            }}
            leftIcon={<IconClipboardCheck size="2.5rem" />}
          >
            {"초중종품 검사"}
          </Work.WorkButton>
          {/* <Work.WorkButton
            onClick={() => {
              openModal(
                <PalletBarcodeBox workData={workData} lotData={lotData} />, null, "파레트 분리",
              )
            }}
            bg={theme?.colors?.grape?.[6]}
            leftIcon={<IconSeparatorVertical size="2.5rem" />}
          >
            {"파레트 분리"}
          </Work.WorkButton> */}
        </Work.WorkFlexBox>
        <Work.WorkFlexBox direction={"row"} p={"12px 16px"} gap={8}>
          <Work.WorkButton
            bg={theme?.colors?.cyan?.[6]}
            leftIcon={<IconArticle size="2.5rem" />}
            onClick={(e) => openModal(<LogsForm workId={workId as string} />, '', '작업 로그 확인')}
          >
            {"작업로그"}
          </Work.WorkButton>
          {workData?.externalCounterSetting !== "HANDWRITING" &&
            workData?.trackingStatus === "WAITING" && (
              <Work.WorkButton
                bg={theme?.colors?.green?.[6]}
                leftIcon={<IconPlayerPlay size="2.5rem" />}
                onClick={async (e) => {
                  e.preventDefault();
                  const startWork = async () => {
                    // 원부자재 남아있을 경우 플러싱 여부 체크
                    !!(workInventoriesData?.data?.rows?.length) && await openModal(
                      <Confirm
                        message="원/부자재가 남아있습니다. 재고를 초기화(플러싱) 하시겠습니까?"
                        yesCallback={async () => {
                          await flushMutate({
                            wmsFlushPutRequest: {
                              locationCode: workData?.locationSetting?.toLocation?.code as string,
                            }
                          })
                          alert('재고가 초기화되었습니다.');
                        }}
                        noCallback={() => { }}
                        commonCallback={() => {
                          closeModal({});
                        }}
                      />
                      , ""
                      , "플러싱 여부 확인"
                    );

                    setOperationCode(workData.operationCode);
                    await startTrackingMutate({
                      worksWorkIdTrackingStartPostRequest: {
                        workId: workData?.id as number,
                      },
                      workId: workData?.id as number,
                    });
                  };
                  if (
                    workData?.equipment?.activeWorkId !== workData?.id &&
                    workData?.equipment?.activeWorkId !== null
                  ) {
                    if (
                      window.confirm(`이미 작업중이었던 작업 지시서가 있습니다. 이전의 작업을 일시정지하시겠습니까?`)
                    ) {
                      startWork();
                    } else {
                      alert("취소했습니다.");
                    }
                  } else {
                    startWork();
                  }
                }}
              >
                {"작업 시작"}
              </Work.WorkButton>
            )}
          {workData?.trackingStatus === "WORKING" && (
            <Work.WorkFlexBox direction="row">
              <Work.WorkButton
                bg={theme?.colors?.orange?.[6]}
                leftIcon={<IconPlayerPause size="2.5rem" />}
                onClick={(e) => {
                  e.preventDefault();
                  openModal(
                    <DownTimeReasonsForm
                      workData={workData}
                    />,
                    "",
                    "",
                    true
                  );
                }}
              >
                {"일시 정지"}
              </Work.WorkButton>
              <Work.WorkButton
                bg={theme?.colors?.red?.[6]}
                leftIcon={<IconSquareOff size="2.5rem" />}
                onClick={(e) => {
                  openModal(
                    <Confirm
                      message={
                        <>
                          {Object.entries(summedByLotName).map(([lotName, { quantity, unitText }], index) => (
                            <p key={index}>
                              <span style={{ color: 'blue' }}>{lotName}</span>의 수량이 <span style={{ color: 'red' }}>{quantity}</span> {unitText} 남아 있습니다.
                            </p>
                          ))}
                          <p>재고를 보정 후 종료하시겠습니까?</p>
                          <p>플러싱 후 종료하시겠습니까?</p>
                        </>
                      }
                      yesMessage2="플러싱 후 종료"
                      yesMessage="재고 보정 후 종료"
                      noMessage="종료"
                      yesCallback={async () => {
                        e.preventDefault();
                        {
                          correctMutate(workData?.equipmentCode as string)
                          await endTrackingMutate({
                            workId: workData?.id as number,
                            worksWorkIdTrackingStartPostRequest: {
                              workId: workData?.id as number,
                            },
                          }, {
                            onSuccess: () => {
                              alert('재고 보정 후 작업이 종료되었습니다.');
                              closeModal({});
                            }
                          });
                        }
                      }}
                      noCallback={async () => {
                        e.preventDefault();
                        await endTrackingMutate({
                          workId: workData?.id as number,
                          worksWorkIdTrackingStartPostRequest: {
                            workId: workData?.id as number,
                          },
                        }, {
                          onSuccess: () => {
                            alert('작업이 종료되었습니다.');
                            closeModal({});
                          }
                        })
                      }}
                      commonCallback={() => { }}
                      yesCallback2={() => {
                        e.preventDefault();
                        flushMutate({
                          wmsFlushPutRequest: {
                            locationCode: workData?.locationSetting?.toLocation?.code as string,
                          }
                        }, {
                          onSuccess: () => {
                            endTrackingMutate({
                              workId: workData?.id as number,
                              worksWorkIdTrackingStartPostRequest: {
                                workId: workData?.id as number,
                              },
                            }, {
                              onSuccess: () => {
                                alert('재고 플러싱 후 작업이 종료되었습니다.');
                                closeModal({});
                              }
                            });
                          }
                        })
                      }}
                    />,
                    "",
                    "작업 종료 확인"
                  )
                  // e.preventDefault();
                  // await endTrackingMutate({
                  //   workId: workData?.id as number,
                  //   worksWorkIdTrackingStartPostRequest: {
                  //     workId: workData?.id as number,
                  //   },
                  // });
                }}
              >
                {"작업 종료"}
              </Work.WorkButton>
            </Work.WorkFlexBox>
          )}
          {workData?.trackingStatus === "PAUSED" && (
            <Work.WorkButton
              bg={theme?.colors?.green?.[6]}
              leftIcon={<IconPlayerPlay size="2.5rem" />}
              onClick={async (e) => {
                e.preventDefault();
                const resumeWork = async () => {
                  setOperationCode(workData.operationCode);
                  await resumeTrackingMutate({
                    workId: workData?.id as number,
                    worksWorkIdTrackingStartPostRequest: {
                      workId: workData?.id as number,
                    },
                  });
                };

                if (
                  workData?.equipment?.activeWorkId !== workData?.id &&
                  workData?.equipment?.activeWorkId !== null
                ) {
                  if (
                    window.confirm(
                      "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?"
                    )
                  ) {
                    resumeWork();
                  } else {
                    alert("취소했습니다.");
                  }
                } else {
                  resumeWork();
                }
              }}
            >
              {"작업 재개"}
            </Work.WorkButton>
          )}
        </Work.WorkFlexBox>
      </Work.WorkHeader>
      <Work.WorkBody>
        <Work.WorkLeftSection>
          <Flex justify={"space-between"} mb={8} w={"100%"} gap={20}>
            <Work.WorkFlexBox w={"20%"}>
              <Work.WorkTitle>{"현재 작업 상태"}</Work.WorkTitle>
              <Work.WorkText c="cyan">
                <Badge size="xl" w={"9.5rem"} h={"2.8rem"} fz={"1.4rem"} color={color}>{status}</Badge>
              </Work.WorkText>
            </Work.WorkFlexBox>
            <Work.WorkFlexBox w={"40%"}>
              <Work.WorkTitle>{"제품코드"}</Work.WorkTitle>
              {/* 제품코드가 온전히 숫자인 경우가 있어서 이 부분만 TEXT로 변경 */}
              <Text size={"2rem"} color="#E9ECEF" >{workData?.item?.code ?? "-"}</Text>
            </Work.WorkFlexBox>
            <Work.WorkFlexBox w={"40%"}>
              <Work.WorkTitle>{"제품명"}</Work.WorkTitle>
              <Work.WorkText>{workData?.item?.name ?? "-"}</Work.WorkText>
            </Work.WorkFlexBox>
            {/* <Work.WorkFlexBox w={"40%"}>
              <Work.WorkTitle>{"현재 로트 번호"}</Work.WorkTitle>
              <Work.WorkText>{lotData?.data.name ?? "-"}</Work.WorkText>
            </Work.WorkFlexBox> */}
          </Flex>
          <Flex justify={"space-between"} mb={8} w={"100%"}>
            {[
              { title: "규격", value: workData?.item?.spec },
              { title: "가로", value: workData?.item?.width },
              { title: "세로", value: workData?.item?.depth },
              { title: "높이", value: workData?.item?.height },
            ].map((item) => {
              return (
                <Work.WorkFlexBox>
                  <Work.WorkTitle>{item.title}</Work.WorkTitle>
                  <Work.WorkText>{item.value}</Work.WorkText>
                </Work.WorkFlexBox>
              );
            })}
          </Flex>
          <Divider w={"100%"} />
          <Work.WorkFlexBox w={"100%"}>
            <Work.WorkFlexBox w={'100%'} direction={'row'} align={'center'} justify={'space-between'}>
              <Work.WorkTitle>{"진행률"}</Work.WorkTitle>
              <Highlight
                color="#ffffff"
                highlight={['양품 수량']}
                sx={{
                  '& [data-highlight="양품 수량"]': { backgroundColor: theme.colors?.blue?.[7], padding: '0.2rem 0.5rem' },
                  // '& [data-highlight="생산 실적"]': { backgroundColor: theme.colors?.blue?.[7], padding: '0.2rem 0.5rem' },
                }}
              >{'양품 수량'}</Highlight>
            </Work.WorkFlexBox>
            <WorkFlexBox w={"100%"} direction={"row"}>
              <Work.WorkProgress
                w={"90%"}
                value={Number(Number(workData?.summary?.percent).toFixed(2))}
              />
              <WorkText w={"10%"} size={"2.5rem"} align="right">
                {Number(Number(workData?.summary?.percent).toFixed(2))}%
              </WorkText>
            </WorkFlexBox>
          </Work.WorkFlexBox>
          <Flex justify={"space-between"} mb={8} w={"100%"}>
            {[
              {
                size: "2.3rem",
                color: theme?.colors?.indigo?.[3],
                children: workData?.targetQuantity,
              },
              {
                color: theme?.colors?.violet?.[4],
                children: workData?.summary?.end,
              },
              {
                color: theme?.colors?.teal?.[4],
                children: workData?.summary?.totalQuantity,
              },
              {
                color: theme?.colors?.red?.[4],
                children: workData?.summary?.defectTotal,
              },
              {
                color: theme?.colors?.orange?.[4],
                children: workData?.summary?.todoQuantity,
              },
            ].map((data, index) => (
              <Work.WorkText
                key={index}
                align="right"
                size={"4rem"}
                fs={"normal"}
                fw={700}
                lh={"130%"}
                w={"100%"}
                color={data.color}
              >
                {data.children}
              </Work.WorkText>
            ))}
          </Flex>
          <Flex justify={"space-between"} mb={8} w={"100%"}>
            {[
              `목표 생산량 (${workData?.item?.unitText})`,
              "생산 수량",
              "양품 수량",
              "불량 수량",
              "생산 잔량",
            ].map((title) => {
              return (
                <Work.WorkTitle align="right" w={"100%"}>
                  {title}
                </Work.WorkTitle>
              );
            })}
          </Flex>

          {/* {lotData?.data.name &&

            (
              <>
                <Work.WorkFlexBox w={"100%"}>
                  <Work.WorkFlexBox w={'100%'} direction={'row'} align={'center'} justify={'space-between'}>
                    <Work.WorkTitle>{"현재 로트 진행도"}</Work.WorkTitle>
                  </Work.WorkFlexBox>
                </Work.WorkFlexBox>
                <Flex justify={"space-between"} mb={8} w={"100%"}>
                  {[
                    {
                      color: theme?.colors?.grape?.[6],
                      children: lotData?.data.name ? lotData?.data.name.split('#').pop() : "-",
                    },
                    {
                      color: theme?.colors?.teal?.[4],
                      children: workData?.currentLotSummary?.totalQuantity ?? "-",
                    },
                    {
                      color: theme?.colors?.red?.[4],
                      children: workData?.currentLotSummary?.defectTotal ?? "-",
                    },

                  ].map((data, index) => (
                    <Work.WorkText
                      key={index}
                      align="right"
                      size={"4rem"}
                      fs={"normal"}
                      fw={700}
                      lh={"130%"}
                      w={"100%"}
                      color={data.color}
                    >
                      {data.children}
                    </Work.WorkText>
                  ))}
                </Flex>
                <Flex justify={"space-between"} mb={8} w={"100%"}>
                  {[
                    "파레트 번호",
                    "양품 수량",
                    "불량 수량",
                  ].map((title) => {
                    return (
                      <Work.WorkTitle align="right" w={"100%"}>
                        {title}
                      </Work.WorkTitle>
                    );
                  })}
                </Flex>
              </>
            )} */}
          <Divider w={"100%"} />
          <Accordion
            w={"100%"}
            chevron={<IconPlus size="2.5rem" />}
            styles={{
              control: {
                ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
              },
              chevron: {
                color: "white",
                '&[data-rotate]': {
                  transform: "rotate(45deg)"
                },
              }
            }}
          >
            <Accordion.Item value="ledgerTable">
              <Accordion.Control>
                <Flex w={"100%"} justify={"space-between"}>
                  <Work.WorkTitle
                    size={"3rem"}
                    c={"#FFFFFF"}
                    weight={700}
                    lh={"130%"}
                  >
                    {"생산가능재고"}
                  </Work.WorkTitle>
                  {workData?.operationOutsourceId && (
                    <Work.WorkBox>{`외주 : ${outsourceData?.data?.name}`}</Work.WorkBox>
                  )}
                  <Work.WorkButton
                    onClick={async () => {
                      if (window.confirm('재고를 초기화 하시겠습니까?')) {
                        await flushMutate({
                          wmsFlushPutRequest: {
                            locationCode: workData?.locationSetting?.toLocation?.code as string,
                          }
                        });
                      }
                    }}
                    bg={theme?.colors?.gray?.[6]}
                    leftIcon={<IconRefreshDot size="2.5rem" />}
                  >
                    {"재고 초기화"}
                  </Work.WorkButton>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex w={"100%"} justify={"space-between"}>
                  <WorkFlexBox>
                    <Work.WorkTitle>{"원부자재 로케이션"}</Work.WorkTitle>
                    <Flex wrap={"wrap"}>
                      <Work.WorkText>
                        [{workData?.locationSetting?.fromLocation?.code}]
                      </Work.WorkText>
                      <Work.WorkText>
                        {workData?.locationSetting?.fromLocation?.name}
                      </Work.WorkText>
                    </Flex>
                  </WorkFlexBox>
                  <WorkFlexBox>
                    <Work.WorkTitle>{"생산 로케이션"}</Work.WorkTitle>
                    <Flex wrap={"wrap"}>
                      <Work.WorkText>
                        [{workData?.locationSetting?.toLocation?.code}]
                      </Work.WorkText>
                      <Work.WorkText>
                        {workData?.locationSetting?.toLocation?.name}
                      </Work.WorkText>
                    </Flex>
                  </WorkFlexBox>
                </Flex>
                <Work.WorkTable
                  columns={["품목코드", "로트명", "로트유효기한", "잔량"]}
                  flex={[2, 2, 1, 1]}
                  data={
                    workInventoriesData?.data?.rows?.map((row) => {
                      return [
                        <Text>{row.itemCode}</Text>,
                        row.lot?.name ?? "-",
                        row.lot?.expiration
                          ? dayjs(row.lot?.expiration).format("YYYY-MM-DD")
                          : "-",
                        <div style={{ textAlign: "right" }}>{(setToLocaleString(row.quantity ?? '') + " " + row.unitText)}</div>,

                      ];
                    }) as string[][]
                  }
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Divider w={"100%"} />
          <Accordion
            w={"100%"}
            chevron={<IconPlus size="2.5rem" />}
            defaultValue="inputLogs"
            styles={{
              control: {
                ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
              },
              chevron: {
                color: "white",
                '&[data-rotate]': {
                  transform: "rotate(45deg)"
                },
              },
              content: {
                padding: 0,
              }
            }}
          >
            <Accordion.Item value="inputLogs">
              <Accordion.Control>
                <Flex w={"100%"} justify={"space-between"}>
                  <Work.WorkTitle
                    size={"3rem"}
                    c={"#FFFFFF"}
                    weight={700}
                    lh={"130%"}
                  >
                    {"투입기록"}
                  </Work.WorkTitle>
                  {workData?.operationOutsourceId && (
                    <Work.WorkBox>{`외주 : ${outsourceData?.data?.name}`}</Work.WorkBox>
                  )}
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Work.WorkTable
                  columns={[
                    "품목코드",
                    "로트명",
                    <><div>로트</div><div>유효기한</div></>,
                    <><div>출고</div><div>로케이션</div></>,
                    "수량",
                    <><div>입고</div><div>로케이션</div></>,
                    "작업자",
                    "일시",
                    "액션"
                  ]}
                  flex={[2, 2, 2, 2, 1, 2, 1, 2, 3]}
                  data={
                    workInputLogsData?.data?.flatMap((row: WorkLogsGet200ResponseRowsInner) => {
                      const inputRow = [
                        row.logData?.incoming?.itemCode || "-",
                        row.logData?.incoming?.lotData?.name || "-",
                        row.logData?.incoming?.lotData?.expiration
                          ? dayjs(row.logData?.incoming?.lotData?.expiration).format("YYYY-MM-DD")
                          : "-",
                        row.logData?.outgoing?.locationData?.name,
                        <div style={{ textAlign: "right" }}>{row.logData?.incoming?.quantity + " " + (row.unitText ?? '')}</div>,
                        row.logData?.incoming?.locationData?.name,
                        row.logData?.incoming?.userCode,
                        dayjs(row.logData?.incoming?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        <WorkFlexBox w={'100%'} align={'center'}>
                          {
                            row.alreadyCanceled ?
                              <Work.WorkText c={'red'}>
                                {'취소됨'}
                              </Work.WorkText>
                              : <WorkButton
                                onClick={onCancelInput}
                                bg={theme?.colors?.gray?.[6]}
                              >{'취소하기'}</WorkButton>
                          }
                        </WorkFlexBox>
                      ];
                      return [inputRow];
                    }) as string[][]
                  }
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Work.WorkLeftSection>
        <Work.WorkRightSection>
          <Accordion
            w={"100%"}
            defaultValue="workTable"
            chevron={<IconPlus size="2.5rem" />}
            styles={{
              control: {
                ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
              },
              chevron: {
                color: "white",
                '&[data-rotate]': {
                  transform: "rotate(45deg)"
                },
              }
            }}
          >
            <Accordion.Item value="workTable">
              <Accordion.Control>
                <Work.WorkTitle
                  size={"3rem"}
                  c={"#FFFFFF"}
                  weight={700}
                  lh={"130%"}
                >
                  {"작업 시간 추적"}
                </Work.WorkTitle>
              </Accordion.Control>
              <Accordion.Panel>
                <Work.WorkTable
                  columns={["타입", "시작시간", "종료시간", "비가동 사유"]}
                  flex={[1, 2, 2, 2]}
                  data={workTrackingData?.data?.reduce((acc, row, index, self) => {
                    // 일시정지인 경우 작업재개가 있는경우 작업재개를 찾아서 endTime에 종료시간을 추가
                    const extendedRow = row as ExtendedWorkLogs;
                    if (extendedRow.workLogType === "TIME_TRACKING_PAUSE") {
                      const nextResumeIndex = self.slice(index + 1).findIndex((r) => r.workLogType === "TIME_TRACKING_RESUME");
                      if (nextResumeIndex !== -1) {
                        extendedRow.endTime = self[index + nextResumeIndex + 1].createdAt;
                      }
                    } else if (extendedRow.workLogType === "TIME_TRACKING_END") {
                      extendedRow.endTime = extendedRow.createdAt;
                    }
                    // 작업로그 중 작업 재개가 아닌경우 배열(acc)에 추가
                    if (extendedRow.workLogType !== "TIME_TRACKING_RESUME") {
                      acc.push(extendedRow);
                    }
                    return acc;
                  }, [] as ExtendedWorkLogs[]).map((row) => {
                    const workLogTypeText = row.workLogType === "TIME_TRACKING_START" ? "작업 시작" :
                      row.workLogType === "TIME_TRACKING_END" ? "작업 종료" :
                        row.workLogType === "TIME_TRACKING_PAUSE" ? "일시 정지" : "";
                    const startTime = row.workLogType !== "TIME_TRACKING_END" ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-";
                    const endTime = row.endTime ? dayjs(row.endTime).format("YYYY-MM-DD HH:mm:ss") : "-";
                    const downtimeReasonName = row.workLogType === "TIME_TRACKING_PAUSE" ? (row.downtimeReasonName as string) : "-";

                    return [
                      workLogTypeText,
                      startTime,
                      endTime,
                      downtimeReasonName,
                    ];
                  })}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            w={"100%"}
            defaultValue="defectTable"
            chevron={<IconPlus size="2.5rem" />}
            styles={{
              control: {
                ":hover": { backgroundColor: `${theme?.colors?.gray?.[7]}` },
              },
              chevron: {
                color: "white",
                '&[data-rotate]': {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="defectTable">
              <Accordion.Control>
                <Work.WorkTitle
                  size={"3rem"}
                  c={"#FFFFFF"}
                  weight={700}
                  lh={"130%"}
                >
                  {/* {"현재 로트 불량기록"} */}
                  {"불량기록"}
                </Work.WorkTitle>
              </Accordion.Control>
              <Accordion.Panel>
                <Work.WorkTable
                  columns={["불량명", "보고수량"]}
                  flex={[2, 1]}
                  // data={
                  //   currentDefectList?.map((defect) => {
                  data={
                    defectList?.map((defect) => {
                      return defect?.defectQuantity !== '0' ? [
                        defect?.defectName ?? "-",
                        <div style={{ textAlign: 'right' }}>{(defect?.defectQuantity ?? "-") + " " + defect?.setUnitText}</div>,
                      ] : [];
                    }) ?? [[]]
                  }
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Work.WorkDefectReportBox
            value={workData?.summary?.defect as DefectObject}
            onSubmit={(data) => {
              onSaveDefects(data);
            }}
          />
          <Work.WorkEndReportBox
            activeType={workData?.trackingStatus}
            onChangeSetting={(settingType) => {
              settingMutate({
                workId: workData?.id as number,
                worksWorkIdDeleteRequest: {
                  externalCounterSetting: settingType,
                },
              });
              setPrevEnd('0')
            }}
            externalCounterSetting={workData?.externalCounterSetting ?? "COUNTER_AND_HANDWRITING"}
            disabled={workData?.externalCounterSetting === "COUNTER_AND_AUTO_REPORT"}
            value={equipmentCounter || "0"}
            onSubmit={(end: string) => {
              onSaveEnd(end);
            }}
          />
        </Work.WorkRightSection>
      </Work.WorkBody>
    </Work >
  );
};
