import { WorksGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import DefaultInstance from "instance/axios";
import { useQuery } from "react-query";

export const GET_ROUTINGS_QUERY_KEY = "get-works";

const getWorksFetch = (params?: any) => {
  if (params?.query) {
    const [beforeDate, afterDate] = params.dates;

    const before = dayjs(
      dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
    ).toISOString();

    const after = afterDate
      ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
      : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

    const date = after ? [before, after] : [before, before];

    params.query.$and.push({
      scheduledAt: {
        $between: date
      }
    })
  }

  const queryString = JSON.stringify(params.query)
  return DefaultInstance.worksGet({
    page: params.activePage ?? 1,
    pageSize: 10,
    populate: [
      "equipment",
      "summary",
      "item",
      "productionPlan",
      "operationData",
    ],
    sort: "-createdAt",
    query: queryString && [queryString],
  });
};

const useWorksGetQuery = (params?: any) => {
  return useQuery<AxiosResponse<WorksGet200Response>>({
    queryKey: params
      ? [
        GET_ROUTINGS_QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
      ]
      : [GET_ROUTINGS_QUERY_KEY],
    enabled: !!params,
    queryFn: () =>
      params ? getWorksFetch(params) : getWorksFetch(),
  });
};

export default useWorksGetQuery;
