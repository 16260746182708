import { useQuery } from "react-query";

import { DefectsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import DefaultInstance from "instance/axios";

export const WORKS_DEFECT_KEY = "get-defect";

const fetchDefectGet = () =>
  DefaultInstance.defectsGet({
    pageSize: 30,
  });

export const useDefectsGet = (options?: { onSuccess: () => void }) => {
  return useQuery([WORKS_DEFECT_KEY], () => fetchDefectGet(), {
    select: (data: AxiosResponse<DefectsGet200Response, any>) =>
      data.data as DefectsGet200Response,
    onSuccess: options?.onSuccess,
  });
};
