import { ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { WORKS_WORK_ID_GET_KEY } from "api/work/useWorksWorkIdGetQuery";
import { ProductionInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

//삭제
const fetchWorkLogsWorkLogIdCancelPerformancePut = ({
    workLogId,
    workLogsWorkLogIdCancelInputPutRequest
}: ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest) =>
    ProductionInstance.workLogsWorkLogIdCancelPerformancePut({
        workLogId,
        workLogsWorkLogIdCancelInputPutRequest,
    });

export const useWorkLogsWorkLogIdCancelPerformancePut = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({
            workLogId,
            workLogsWorkLogIdCancelInputPutRequest,
        }: ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest) =>
            fetchWorkLogsWorkLogIdCancelPerformancePut({ workLogId, workLogsWorkLogIdCancelInputPutRequest }),
        {
            onSuccess: () => {
                customNotification.success({
                    message: "실적이 성공적으로 취소되었습니다.",
                });
                queryClient.invalidateQueries(WORKS_WORK_ID_GET_KEY);
            },
            onError: (error: any) => {
                customNotification.error({
                    message: error.response.data.message
                });
            }
        }
    );
};
export default useWorkLogsWorkLogIdCancelPerformancePut;
