import { AuthSignupPost201ResponseEquipment, MasterApiEquipmentsEquipmentIdGetRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { MasterInstance } from "instance/axios";
import { useQuery } from "react-query";

export const GET_INSPEC_QUERY_KEY = "get-spc-inspections";

const getEquipmentCountFetch = (params: MasterApiEquipmentsEquipmentIdGetRequest) => {
    return MasterInstance.equipmentsEquipmentIdGet(params)
};

const useEquipmentsEquipmentIdGet = (
    params: MasterApiEquipmentsEquipmentIdGetRequest,
    options?: {
        onSuccess?: (data: AxiosResponse<AuthSignupPost201ResponseEquipment, any>) => void;
        enabled?: boolean;
    }
) => {
    return useQuery<AxiosResponse<AuthSignupPost201ResponseEquipment, any>>({
        queryKey: params
            ? [GET_INSPEC_QUERY_KEY, params]
            : [GET_INSPEC_QUERY_KEY],
        queryFn: () => getEquipmentCountFetch(params),
        enabled: options?.enabled,
        onSuccess: options?.onSuccess,
    });
};

export default useEquipmentsEquipmentIdGet;
