import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconDeviceFloppy } from "@tabler/icons-react";
import useProductionTrackingActionMutationQuery from "api/workTracking/useWorkTarckingQuery";
import { useModal } from "context/ModalStackManager";
import { DowntimeReasonsAutoComplete } from "features/autoComplete/downtimeReasons/downtimeReasons-autoComplete";
import { WorkButton } from "features/work/workButton";
import { WorkTitle } from "features/work/workTitle";
import { useState } from "react";
import { theme } from "styles/theme";
import { FormMain } from "../FormMain";
const Pause = Object.assign(FormMain, {
  PauseTitle: WorkTitle,
  PauseButton: WorkButton,
});

interface PauseFormProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const DownTimeReasonsForm = (params: PauseFormProps) => {
  const { workData } = params;
  const { closeModal } = useModal();
  const [downtimeReasonCode, setDowntimeReasonCode] = useState<string>("");
  const { mutate: pauseMutation } =
    useProductionTrackingActionMutationQuery("pause");

  const pauseHandler = async () => {
    await pauseMutation(
      {
        worksWorkIdTrackingPausePostRequest: {
          workId: workData?.id || 0,
          downtimeReasonCode: downtimeReasonCode,
        },
        workId: workData?.id || 0,
      },
      {
        onSuccess: (res) => {
          closeModal({});
        },
      }
    );
  };

  return (
    <Pause w={"60rem"} h={"18rem"} m={"2rem"}>
      <Pause.PauseTitle size={"3rem"} mb={"lg"} c={"#000000"} weight={700} lh={"130%"}>
        {"비가동 사유 선택"}
      </Pause.PauseTitle>
      <Flex align={"center"} gap={8}>
        <DowntimeReasonsAutoComplete
          styles={{
            input: {
              height: "5rem",
              width: "45rem",
            },
          }}
          value={downtimeReasonCode}
          maxDropdownHeight={400}
          rightSectionWidth={60}
          width="35rem"
          size="2rem"
          routingCode={workData?.operationData?.routingCode}
          onChange={(e) => setDowntimeReasonCode(e ?? "")}
        />
        <Pause.PauseButton
          disabled={!downtimeReasonCode}
          h="5rem"
          size="1.5rem"
          rightIcon={<IconDeviceFloppy />}
          onClick={pauseHandler}
          bg={theme?.colors?.orange?.[6]}
        >
          {"저장"}
        </Pause.PauseButton>
      </Flex>
    </Pause>
  );
};
