import { Button, Text, TextInput } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { OutgoingAllFormItemProps, Td } from "./OutgoingAllForm";

export const BulkAllFormRow: React.FC<OutgoingAllFormItemProps> = ({ formData, seq, onChange, onDelete }) => {
    const { lotId, lotName, itemCode, itemName, spec, quantity } = formData;

    return <tr>
        <Td width={7}><Text align="right" size="2rem">{seq + 1}</Text></Td>
        <Td width={14}><Text size="2rem">{lotId}</Text></Td>
        <Td width={14}><Text size="2rem">{lotName}</Text></Td>
        <Td width={14}><Text size="2rem">{itemCode}</Text></Td>
        <Td width={14}><Text size="2rem">{itemName}</Text></Td>
        <Td width={14}><Text size="2rem">{spec}</Text></Td>
        <Td width={14}><TextInput size="xl" type="number" value={quantity} onChange={(e) => onChange(seq, e.target.value)} /></Td>
        <Td width={7}><Button leftIcon={<IconTrash />} color="red" variant="white" onClick={() => onDelete(seq)}>삭제</Button></Td>
    </tr>
}
