import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { useAuthWhoamiGet } from "api/useAuthWhoamiGet";
import { useLocalStorage } from "context/LocalStorageProvider";
import { menuList } from "modules/sidebar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { deviceSize, theme } from "styles/theme";

function Index() {
  const { workId } = useLocalStorage();
  const location = useLocation();
  const { setEquipmentCode } = useLocalStorage();
  const { data: user, refetch } = useAuthWhoamiGet({
    onSuccess: (code) => {
      setEquipmentCode(code);
    }
  });

  const navigate = useNavigate();
  const LogOutClick = () => {
    localStorage.clear();

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
      method: 'POST', // HTTP 메소드 지정
      headers: {
        'Content-Type': 'application/json' // 내용 유형을 JSON으로 설정
      }
    });

    navigate("/");
    window.location.reload();

  };

  return (
    <Aside>
      {/* <AsideLogoBox><icons.DashboardLogo /></AsideLogoBox> */}
      <section>
        <Title>생산 POP</Title>
        <MenuUl>
          {menuList.map((menu) => {
            return (
              <NavbarLink
                onClick={() => refetch()}
                key={menu.id}
                isSelected={
                  (menu.name === "POP" ? `${menu.id}/${workId}` : menu.id) ===
                  location.pathname
                }
                to={menu.name === "POP" ? `${menu.id}/${workId}` : menu.id}
              >
                {/* {menu.icon} */}
                {menu.name}
              </NavbarLink>
            );
          })}
        </MenuUl>
      </section>
      <LogOutButton onClick={LogOutClick}>로그아웃</LogOutButton>
    </Aside>
  );
}

const NavbarLink = styled(NavLink) <{ isSelected?: boolean }>`
  width: 100%;
  padding: 16px;
  color: ${theme.colors?.gray?.[9]};
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  transition: all 0.15s ease-in;
  &:focus {
    color: ${theme.colors?.blue?.[6]};
    background-color: ${theme.colors?.blue?.[0]};
    & path {
      fill: black;
    }
  }
  & path {
    fill: ${theme.colors?.blue?.[0]};
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors?.blue?.[6]};
      background-color: ${theme.colors?.blue?.[0]};
      & path {
        fill: white;
      }
    `}

  @media (max-width : 480px) {
    padding: 8px;
  }
`;
const Aside = styled.aside`
  flex : 0.6;
  height: auto;
  min-height: 100%;
  background-color: ${theme.colors?.gray?.[8]};
  padding: 20px 16px;
  box-shadow: 0px 4px 20px rgba(6.82, 6.02, 18.06, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  /* @media (max-width: 1920px) {
    height: 1020px;
  }

  @media (max-width: 1200px) {
    height: 1720px;
  }

  @media (min-width: 820px) {
    height: 820px;
  }

  @media (max-width: ${deviceSize.mobileM}) {
    padding: 16px 12px;
    height: 700px;
  } */
`;
const AsideLogoBox = styled.div``;
const MenuUl = styled.ul`
  width: 100%;
  margin-top: 25px;
  font-size: 20px;

  & a {
    font-size: 20px;
    border: 1px solid ${theme.colors?.blue?.[6]};
    margin-bottom: 4px;
    width: 100%;
    color: ${theme.colors?.gray?.[6]};
  }

  @media (max-width: 1200px) {
    & a {
      font-size: 18px;
    }
  }

  @media (max-width: ${deviceSize.mobileM}) {
    & a {
      font-size: 14px;
    }
  }
`;


const Title = styled.p`
  color: ${theme.colors?.white?.[0]};
  font-size: 24px;

  @media (max-width: ${deviceSize.tablet}) {
    font-size: 24px;
    // font-size: 28px;
  }

  @media (max-width: ${deviceSize.mobileM}) {
    font-size: 24px;
    // font-size: 22px;
  }
`;

const LogOutButton = styled<any>(Button)`
  height: 42px;
  border-radius: 4px;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 400;
  color: ${theme.colors?.gray?.[2]};
  background-color: inherit;

  &:hover {
    background-color: ${theme.colors?.gray?.[5]};
  }
  @media (max-width: ${deviceSize.mobileM}) {
    font-size: 16px;
  }
`;


export default Index;
