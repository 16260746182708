import styled from "@emotion/styled";

import { Outlet, Route, Routes } from "react-router-dom";

import { useLocalStorage } from "context/LocalStorageProvider";
import ProtectedRoute from "context/ProtectedRoute";
import Aside from "layout/Aside";
import { Footer } from "layout/footer";
import Auth from "pages/auth";
import { Inspections } from "pages/inspections";
import { Inventory } from "pages/inventory";
import { WarningPopPageInfo } from "pages/warning/warningPopPageInfo";
import { WorkPage } from "pages/work";
import { Works } from "pages/works";
import { useCheckAuth } from "utils/checkAuth";

function Index() {
  const { workId } = useLocalStorage();
  const auth = useCheckAuth();

  return (
    <Routes>
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/">
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/works"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              <Works />
            </ProtectedRoute>
          }
        />
        <Route
          path="/work/:workId"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              {workId ? <WorkPage /> : <WarningPopPageInfo />}
            </ProtectedRoute>
          }
        />
        <Route
          path="/inventory"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              <Inventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inspections"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              <Inspections />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

const Layout = ({ auth }: { auth: boolean }) => {
  return (
    <Main>
      {!auth ? (
        <Outlet />
      ) : (
        <>
          <Container>
            <Aside />
            <ContentSection>
              <Outlet />
            </ContentSection>
          </Container>
          <Footer />
        </>
      )}
    </Main>
  );
};
const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const ContentSection = styled.section`
  height: auto;
  flex : 10;
  overflow: hidden;
  /* @media (min-width: 768px) and (max-width: 1024px) {
    overflow: hidden;
  } */
`;

export default Index;
